import React from 'react'
import { Form } from 'react-bulma-components'

import { CustomChangeEvent } from 'hooks/useForm'

import ErrorList from 'components/ErrorList'
const { Field, Label, Control, Textarea } = Form

interface Props {
  name: string
  value: string
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement> | CustomChangeEvent
  ) => void
  label?: string
  helpText?: string
  placeholder?: string
  id?: string
  errors?: { [key: string]: any } | null
  disabled?: boolean
  readOnly?: boolean
}

const TextArea: React.FC<Props> = ({
  name,
  value,
  onChange,
  label,
  helpText,
  placeholder = '',
  id,
  errors,
  disabled,
  readOnly,
}) => {
  return (
    <Field>
      {label && (
        <>
          <Label htmlFor={id}>{label}</Label>
          {helpText && <p className="is-size-7 mb-3">{helpText}</p>}
        </>
      )}
      <Control>
        <Textarea
          value={value}
          onChange={onChange}
          id={id}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
        />
        {errors && errors[name] && <ErrorList errors={errors[name]} />}
      </Control>
    </Field>
  )
}

export default TextArea
