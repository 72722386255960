import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const LeftArrow: React.FC<Props> = ({
  color = '#fff',
  width,
  height,
}) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M13.6666 6.16658H3.52498L8.18331 1.50825L6.99998 0.333252L0.333313 6.99992L6.99998 13.6666L8.17498 12.4916L3.52498 7.83325H13.6666V6.16658Z" 
      fill="#fff"
    />
  </svg>
)

export default LeftArrow
