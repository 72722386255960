import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const Twitter: React.FC<Props> = ({
  color,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 30 30" fill="none">
    <path
      d="M29.5341 5.82353C28.4471 6.31765 27.2753 6.64235 26.0612 6.79765C27.3035 6.04941 28.2635 4.86353 28.7153 3.43765C27.5435 4.14353 26.2447 4.63765 24.8753 4.92C23.76 3.70588 22.1929 3 20.4141 3C17.0965 3 14.3859 5.71059 14.3859 9.05647C14.3859 9.53647 14.4424 10.0024 14.5412 10.44C9.51529 10.1859 5.04 7.77176 2.06118 4.11529C1.53882 5.00471 1.24235 6.04941 1.24235 7.15059C1.24235 9.25412 2.30118 11.1176 3.93882 12.1765C2.93647 12.1765 2.00471 11.8941 1.18588 11.4706C1.18588 11.4706 1.18588 11.4706 1.18588 11.5129C1.18588 14.4494 3.27529 16.9059 6.04235 17.4565C5.53412 17.5976 4.99765 17.6682 4.44706 17.6682C4.06588 17.6682 3.68471 17.6259 3.31765 17.5553C4.08 19.9412 6.29647 21.72 8.96471 21.7624C6.90353 23.4 4.29176 24.36 1.44 24.36C0.96 24.36 0.48 24.3318 0 24.2753C2.68235 25.9976 5.87294 27 9.28941 27C20.4141 27 26.5271 17.7671 26.5271 9.76235C26.5271 9.49412 26.5271 9.24 26.5129 8.97176C27.6988 8.12471 28.7153 7.05176 29.5341 5.82353Z"
      fill={color}
    />
  </svg>
)

export default Twitter
