import React from 'react'

import './styles.scss'
import ArrowDown from 'assets/icons/ArrowDown'

interface Props {
  title: string
  body: string
  bodyBold: boolean
  noMargin: boolean
}

const TitleSection: React.FC<Props> = ({ title, body, bodyBold, noMargin }) => {
  return (
    <div className={noMargin ? 'box-section no-margin' : 'box-section'}>
      <div className={noMargin ? 'title-box no-margin' : 'title-box'}>
        { title }
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div className={bodyBold ? 'title-box bold' : 'body-box'}>
          { body }
        </div>
        <div>
          <ArrowDown />
        </div>
      </div>
    </div>
  )
}

export default TitleSection
