import React, { useState } from 'react'
import Helmet from 'react-helmet'
import {
  Hero,
  Container,
  Heading,
  Button,
  Columns,
} from 'react-bulma-components'
import ContactForm from 'components/forms/Contact'
import HowItWorks from 'assets/images/how_it_works.png'
import MobileHowItWorks from 'assets/images/mobile_how_it_works.png'
import HowItWorksMobile1 from 'assets/images/laptopbusiness_responsive.png'
import HowItWorksMobile2 from 'assets/images/winit_responsive.png'
import HowItWorksMobile3 from 'assets/images/cv_responsive.png'
import UpluadDev from 'assets/images/upload_2.png'

import { scrollTo } from 'helpers/scrollTo'
import './styles.scss'
import SectionCard from 'components/SectionCard'
import TitleSection from 'components/TitleSection'
import CompanySection from 'assets/icons/CompanySection'
import DeveloperCard from 'components/DeveloperCard'
import Expand from 'assets/icons/Expand'
import CashFast from 'assets/icons/CashFast'
import Finance from 'assets/icons/Finance'
import FileInput from 'components/FileInput'
import ServiceCard from 'components/ServiceCard'
import Briefcase from 'assets/icons/Briefcase'
import Contact from 'assets/icons/Contact'
import PersonFind from 'assets/icons/PersonFind'

interface props { }

const Home: React.FC<props> = () => {
  const [isCompany, setCompany] = useState(true)
  const enableCompany = (e: any) => {
    setCompany(true)
    scrollTo(e)
  }
  const disableCompany = (e: any) => {
    setCompany(false)
    scrollTo(e)
  }
  const toContactUs = (e: any) => {
    scrollTo(e)
  }

  return (
    <div id="home">
      <Helmet>
        <title>WinIT: Talento TI a un click</title>
      </Helmet>

      <Hero id="start" color="dark" size="fullheight">
        <Hero.Header />
        <Hero.Body>
          <Container breakpoint="desktop">
            <Columns>
              <Columns.Column desktop={{ size: 'half' }}>
                <Heading className="is-size-2-mobile title-start" style={{ paddingRight: '2.75rem' }}>
                  Talento TI a un click
                </Heading>
                <p className="is-size-4 is-size-5-mobile">
                  Queremos nivelar la industria del HR en servicios <br />
                  TI. ¿Cómo? Hacemos el match entre developers y <br />
                  contratante para que todos ganen y paguen lo justo. <br />
                  Es un Win-IT.
                </p>
                <div className="actions-start">
                  <Button
                    color="success"
                    renderAs="a"
                    rounded={true}
                    href="#soy-empresa"
                    size="medium"
                    onClick={enableCompany}
                    className="button-start company"
                  >
                    Contrata Developers
                  </Button>
                  <Button
                    color="warning"
                    renderAs="a"
                    href="#soy-developer"
                    size="medium"
                    onClick={disableCompany}
                    rounded={true}
                    className="button-start developer"
                  >
                    Busco Trabajo
                  </Button>
                </div>
                <p style={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 400, fontSize: '18px', lineHeight: '22px', color: '#FDFDFD' }}>
                  ¿Te quedan dudas? <a href='#contactanos' onClick={toContactUs} style={{ color: '#25C928', fontWeight: 'bold' }}>Contáctanos.</a>
                </p>
              </Columns.Column>
            </Columns>
          </Container>
        </Hero.Body>
        <Hero.Footer></Hero.Footer>
      </Hero>
      <Hero color="dark" size="fullheight" id="como-funciona">
        <Hero.Body>
          <Container breakpoint="desktop" className="">
            <TitleSection
              title='Cómo funciona'
              body='Si contratas pagas lo justo. Si desarrollas, ganas lo que tú decidas.'
              bodyBold={false}
              noMargin={false}
            />
            <div className="is-hidden-tablet" style={{ textAlign: 'center' }}>
              <img
                src={HowItWorksMobile1}
                alt="Como Funciona"
                className="my-2"
              />
              <p className="mb-3 is-size-6 has-text-weight-bold has-text-monospace">
                EMPRESAS
              </p>
              <p>
                Sube tu requerimiento de talento TI y cuánto quieres pagar.
              </p>
              <img
                src={HowItWorksMobile2}
                alt="Como Funciona"
                className="my-2"
              />
              <p className="mb-3 is-size-6 has-text-weight-bold has-text-monospace">
                WINIT
              </p>
              <p className="mb-4">
                Contamos con una comunidad TI clusterizada y generamos el match entre requierimientos y habilidades.
              </p>
              <img
                src={HowItWorksMobile3}
                alt="Como Funciona"
                className="my-2"
              />
              <p className="mb-3 is-size-6 has-text-weight-bold has-text-monospace">
                DEVELOPERS
              </p>
              <p>
                Presentamos oportunidades que hacen match con tu perfil para que elijas.
              </p>
            </div>

            <div className="is-hidden-mobile">
              <img
                src={HowItWorks}
                alt="Como Funciona"
                className="is-block my-6 py-4"
              />

              <Columns className="mt-6">
                <Columns.Column style={{ textAlign: 'center', justifyItems: 'center', display: 'inline-grid' }}>
                  <p className='title-how-works'>
                    EMPRESAS
                  </p>
                  <p className='text-how-works'>
                    Sube tu requerimiento de talento TI <br />
                    y cuánto quieres pagar.
                  </p>
                </Columns.Column>
                <Columns.Column style={{ textAlign: 'center', justifyItems: 'center', display: 'inline-grid' }}>
                  <p className='title-how-works'>
                    WINIT
                  </p>
                  <p className='text-how-works'>
                    Contamos con una comunidad TI <br />
                    clusterizada y generamos el match entre  <br />
                    requierimientos y habilidades.
                  </p>
                </Columns.Column>
                <Columns.Column style={{ textAlign: 'center', justifyItems: 'center', display: 'inline-grid' }}>
                  <p className='title-how-works'>
                    DEVELOPERS
                  </p>
                  <p className='text-how-works'>
                    Presentamos oportunidades que <br />
                    hacen match con tu perfil para <br />
                    que elijas.
                  </p>
                </Columns.Column>
              </Columns>
            </div>
          </Container>
        </Hero.Body>
      </Hero>
      <Hero color="dark" size="fullheight" id="soy-empresa">
        <Hero.Body>
          <Container breakpoint="desktop">
            <Columns>
              <Columns.Column desktop={{ size: 'half' }} style={{ marginBlock: 'auto' }}>
                <Heading className="is-size-2 is-size-3-mobile title-company">
                  <div>
                    Recruiter, <label style={{ color: '#D0FE17' }}>ahorra tiempo y</label> <br />
                    <label style={{ color: '#25C928' }}>dinero</label> en la búsqueda de <br />
                    equipos de desarrollo
                  </div>
                </Heading>
                <p className="text-company mb-6">
                  Tu negocio es lo más importante, nosotros nos ocupamos<br />
                  del resto.
                </p>
                <Button
                  renderAs="a"
                  onClick={enableCompany}
                  href="#contactanos"
                  className="button-company label-button"
                  rounded={true}
                >
                  Publica tu anuncio
                </Button>
              </Columns.Column>
              <Columns.Column desktop={{ size: 'half' }} style={{ display: 'flex', justifyContent: 'center' }}>
                <CompanySection />
              </Columns.Column>
            </Columns>
            <TitleSection
              title='Conoce nuestos servicios'
              body=''
              bodyBold={false}
              noMargin={true}
            />

            <div className='service-card-group'>
              <ServiceCard
                reverseIcon={<Briefcase variant='secondary' />}
                icon={<Briefcase />}
                showArrow={true}
                title='Bolsa Laboral'
                detail='Contrata planes de anuncios y obtén resultados instantáneos de perfiles coincidentes.' />
              <ServiceCard
                reverseIcon={<Contact variant='secondary' />}
                icon={<Contact />}
                showArrow={true}
                title='Outsourcing'
                detail='Búsqueda y subcontratación de perfiles TI para proyectos de desarrollo.' />
              <ServiceCard
                reverseIcon={<PersonFind variant='secondary' />}
                icon={<PersonFind />}
                showArrow={true}
                title='Headhunting'
                detail='Búsqueda de perfiles TI para proyectos de desarrollo' />
            </div>
          </Container>
        </Hero.Body>
      </Hero>
      {/* ---- */}

      <Hero color="dark" size="fullheight" id="soy-developer">
        <Hero.Body>
          <Container breakpoint="desktop">
            <Columns>
              <Columns.Column desktop={{ size: 'half' }} style={{ marginBlock: 'auto' }}>
                <Heading className="is-size-2 is-size-3-mobile title-company">
                  <div>
                    Developer, encuentra tu <br />
                    <label style={{ color: '#25C928' }}>próximo gran proyecto</label>
                  </div>
                </Heading>
                <p className="text-company">
                  Ahora, entregarnos tu data es 120 veces más <br />
                  rápido. Sube tu CV para que Open AI llene el <br />
                  formulario por ti, así guardamos todos tus datos <br />
                  para que puedas hacer match.
                </p>
                <br />
                <p className="text-company mb-5">
                  En WinIT, tú determinas el valor de tu trabajo y <br />
                  experiencia, las ofertas llegan a tu correo y tú<br />
                  decides al final en qué proyectos participar.
                </p>
                <Button
                  renderAs="a"
                  href="#contactanos"
                  onClick={disableCompany}
                  className="button-company label-button cv-label"
                  rounded={true}
                >
                  Sube tu CV
                </Button>
              </Columns.Column>
              <Columns.Column desktop={{ size: 'half' }} style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={UpluadDev}
                  alt="Como Funciona"
                  className="is-block my-6"
                />
              </Columns.Column>
            </Columns>
          </Container>
        </Hero.Body>
      </Hero>
      <Hero color="dark" size="fullheight" id="developer-cards">
        <Hero.Body style={{ margin: '0px' }}>
          <Container breakpoint="desktop">
            <Columns.Column>
              <TitleSection
                title='¿Por qué unirte a WinIT?'
                body=''
                bodyBold={false}
                noMargin={true}
              />
              <div className='developer-card-group'>
                <DeveloperCard
                  icon={<Expand />}
                  title='Optimiza tu tiempo'
                  subtitle=''
                  body='Carga tu CV y deja que las oportunidades lleguen a ti. Te contactaremos cada vez que tu perfil haga match con una vacante.'
                />
                <DeveloperCard
                  icon={<CashFast />}
                  title='Gana lo que mereces'
                  subtitle=''
                  body='Te presentaremos oportunidades que cumplan tus expectativas, nos comprometemos a respetar tus pretensiones de sueldo.'
                />
                <DeveloperCard
                  icon={<Finance />}
                  title='Carrera Profesional'
                  subtitle=''
                  body='Tenemos oportunidades de todo tipo, con empresas grandes y tradicionales o pequeñas e innovadoras. Tú elijes el desafío.'
                />
              </div>
              <div style={{ justifyContent: 'center', marginTop: '40px', display: 'flex' }}>
                <Button
                  renderAs="a"
                  href="#oportunidades"
                  size="medium"
                  onClick={disableCompany}
                  rounded={true}
                  className="ofertas-boton developer"
                >
                  Conoce nuestras ofertas laborales
                </Button>
              </div>
            </Columns.Column>
          </Container>
        </Hero.Body>
      </Hero>
      <Hero color="dark" size="fullheight" id="oportunidades">
        <Hero.Body style={{ margin: '0px' }}>
          <Container breakpoint="desktop">
            <Heading className="">
              <TitleSection
                title='Revisa nuestras'
                body='vacantes disponibles'
                bodyBold={true}
                noMargin={false}
              />
            </Heading>
            <SectionCard key="section-card" />
          </Container>
        </Hero.Body>
      </Hero>
      <Hero color="dark" size="fullheight" id="contactanos">
        <Hero.Body>
          <Container breakpoint="desktop">
            <div className="has-text-centered">
              <div className="is-size-3-mobile title-contact-us">
                Comienza Ahora
              </div>
              <p className="text-contact-us mb-3">
                Cuéntanos qué buscas y nosotros lo encontramos.
              </p>
              <div className="actions mt-5 mb-6">
                <Button
                  onClick={() => setCompany(true)}
                  outlined={!isCompany}
                  color={!isCompany ? 'white' : ''}
                  className={isCompany ? 'button-forms' : 'is-company'}
                >
                  Soy empresa, buscando equipo
                </Button>
                <Button
                  onClick={() => setCompany(false)}
                  outlined={isCompany}
                  color={isCompany ? 'white' : ''}
                  className={!isCompany ? 'button-forms dev' : 'is-company'}
                >
                  Soy Developer, ofrezco mis servicios
                </Button>
              </div>
            </div>
            {isCompany ? (
              <>
                <ContactForm
                  initialValues={{
                    name: '',
                    email: '',
                    message: '',
                  }}
                />

                {/* <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '30px' }}>
                  <ComputerBG width={50} height={50} />
                  <p className="is-uppercase" style={{ fontWeight: 700, fontSize: '36px'}}>Empresa</p>
                </div>
                <CompanyForm
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    position: '',
                    company: '',
                    projectDuration: '',
                    aboutTheProject: '',
                    budget: '',
                  }}
                /> */}
              </>
            ) : (
              <>
                <FileInput />
                {/*<DeveloperForm
                  initialValues={{
                    country: '349507e41dd8c71c10c9df6d',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    seniority: '',
                    abilities: [],
                    saasTags: [],
                    salary: '',
                    availability: '',
                    availabilityType: '',
                    coverLetter: '',
                    profile: '',
                    workModality: [],
                    nacionality: ""
                  }}
                  isPostulation={false}
                  idPostulation={''}
                />*/}
              </>
            )}
          </Container>
        </Hero.Body>
      </Hero>
    </div>
  )
}

export default Home
