import React from 'react'

interface Props {}

const WinITAlt: React.FC<Props> = () => (
  <svg width="133" height="31" viewBox="0 0 133 31" fill="none">
    <path
      d="M9.99262 15.9457L4.23479 26.6388H0L1.70878 8.1487C1.22586 8.07444 0.891535 7.92593 0.631504 7.70316C0.40862 7.44325 0.297178 7.14623 0.297178 6.77494C0.297178 5.95811 0.668651 5.28979 1.37445 4.80712C1.74592 4.51009 2.37743 4.3987 3.26896 4.3987H8.321C9.17538 4.3987 9.73259 4.51009 10.0298 4.76999C10.327 5.02989 10.4755 5.40118 10.4755 5.88385C10.4755 6.62642 10.1041 7.25761 9.39827 7.74028C9.02679 8.00019 8.39529 8.1487 7.50376 8.1487H5.53495L4.53197 18.6933L9.36112 9.93088H13.2987L14.376 18.6933L17.8307 8.1487H15.8619C15.0075 8.1487 14.4503 8.00019 14.1531 7.74028C13.8559 7.48038 13.7074 7.1091 13.7074 6.62642C13.7074 5.88385 14.0788 5.25266 14.7846 4.76999C15.1561 4.51009 15.7876 4.36157 16.642 4.36157H21.6569C22.4741 4.36157 23.0685 4.47296 23.3657 4.73286C23.6628 4.99276 23.8114 5.36405 23.8114 5.84672C23.8114 6.36652 23.6257 6.8492 23.2171 7.29474C22.8084 7.74028 22.2884 8.00019 21.5826 8.11157L15.4904 26.6388H11.3299L9.99262 15.9457Z"
      fill="black"
    />
    <path
      d="M36.1448 8.1487L32.9873 22.8888H36.7763C37.6307 22.8888 38.1879 23.0002 38.4851 23.2601C38.7823 23.52 38.9309 23.8913 38.9309 24.3739C38.9309 25.1165 38.5594 25.7477 37.8536 26.2675C37.4821 26.5274 36.8506 26.6388 35.9962 26.6388H24.6292C23.8119 26.6388 23.2176 26.4903 22.9204 26.2304C22.6232 25.9705 22.4746 25.5992 22.4746 25.1536C22.4746 24.4111 22.8461 23.7799 23.5519 23.2972C23.9605 23.0373 24.592 22.8888 25.4464 22.8888H29.2354L32.3558 8.1487H28.5296C27.7124 8.1487 27.118 8.00019 26.8208 7.74028C26.5237 7.48038 26.3751 7.1091 26.3751 6.62642C26.3751 5.88385 26.7466 5.25266 27.4523 4.76999C27.861 4.51009 28.4925 4.36157 29.3469 4.36157H40.7139C41.5683 4.36157 42.1255 4.47296 42.4227 4.73286C42.7199 4.99276 42.8685 5.36405 42.8685 5.84672C42.8685 6.58929 42.497 7.22048 41.7912 7.74028C41.4197 8.00019 40.7882 8.11157 39.9338 8.11157H36.1448V8.1487Z"
      fill="black"
    />
    <path
      d="M51.4115 11.4903L48.9969 22.8888H50.3714C51.2258 22.8888 51.783 23.0002 52.0802 23.2601C52.3773 23.52 52.5259 23.8913 52.5259 24.3739C52.5259 25.1165 52.1545 25.7477 51.4487 26.2675C51.0772 26.5274 50.4457 26.6388 49.5913 26.6388H44.5764C43.7592 26.6388 43.1648 26.4903 42.8676 26.2304C42.5705 25.9705 42.4219 25.5992 42.4219 25.1536C42.4219 24.5596 42.6819 24.0027 43.1648 23.5571C43.6477 23.1116 44.3164 22.8888 45.2079 22.8888L48.3283 8.1487H47.7339C46.9167 8.1487 46.3223 8.00019 46.0252 7.74028C45.728 7.48038 45.5794 7.1091 45.5794 6.62642C45.5794 5.88385 45.9509 5.25266 46.6567 4.76999C47.0653 4.51009 47.6968 4.36157 48.5512 4.36157H52.8974L59.3239 19.473L61.7385 8.1487H60.364C59.5468 8.1487 58.9524 8.00019 58.6552 7.74028C58.3581 7.48038 58.2095 7.1091 58.2095 6.62642C58.2095 5.88385 58.5809 5.25266 59.2867 4.76999C59.6954 4.51009 60.3269 4.36157 61.1813 4.36157H66.159C66.9762 4.36157 67.5706 4.47296 67.8678 4.73286C68.1649 4.99276 68.3135 5.36405 68.3135 5.84672C68.3135 6.47791 68.0535 6.99771 67.5706 7.44326C67.0877 7.8888 66.3819 8.11157 65.5275 8.11157L61.5899 26.6017H57.8752L51.4115 11.4903Z"
      fill="black"
    />
    <path
      d="M100.93 8.1487L97.7725 22.8888H101.562C102.416 22.8888 102.973 23.0002 103.27 23.2601C103.567 23.52 103.716 23.8913 103.716 24.3739C103.716 25.1165 103.345 25.7477 102.639 26.2675C102.267 26.5274 101.636 26.6388 100.781 26.6388H89.3772C88.56 26.6388 87.9656 26.4903 87.6684 26.2304C87.3712 25.9705 87.2227 25.5992 87.2227 25.1536C87.2227 24.4111 87.5941 23.7799 88.2999 23.2972C88.7085 23.0373 89.3401 22.8888 90.1944 22.8888H93.9835L97.1038 8.1487H93.3148C92.4976 8.1487 91.9032 8.00019 91.606 7.74028C91.3089 7.48038 91.1603 7.1091 91.1603 6.62642C91.1603 5.88385 91.5317 5.25266 92.2375 4.76999C92.6462 4.51009 93.2777 4.36157 94.1321 4.36157H105.499C106.354 4.36157 106.911 4.47296 107.208 4.73286C107.505 4.99276 107.654 5.36405 107.654 5.84672C107.654 6.58929 107.282 7.22048 106.576 7.74028C106.205 8.00019 105.573 8.11157 104.719 8.11157H100.93V8.1487Z"
      fill="black"
    />
    <path
      d="M123.441 8.14844L120.32 22.8885H122.846C123.663 22.8885 124.258 23.0371 124.555 23.2969C124.852 23.5569 125.001 23.9281 125.001 24.3737C125.001 25.1163 124.629 25.7474 123.923 26.2301C123.515 26.49 122.883 26.6385 122.029 26.6385H113.188C112.371 26.6385 111.776 26.49 111.479 26.2301C111.182 25.9702 111.033 25.5989 111.033 25.1534C111.033 24.4108 111.405 23.7796 112.111 23.2969C112.519 23.0371 113.151 22.8885 114.005 22.8885H116.531L119.652 8.14844H115.565L114.822 11.7128C114.637 12.6039 114.302 13.2722 113.894 13.6435C113.448 14.0148 112.965 14.2375 112.371 14.2375C111.925 14.2375 111.591 14.089 111.293 13.8291C110.996 13.5692 110.885 13.2351 110.885 12.8638C110.885 12.6039 110.922 12.2326 111.033 11.7128L112.594 4.39844H132.096L130.536 11.7128C130.35 12.6039 130.053 13.2722 129.607 13.6435C129.161 14.0148 128.678 14.2375 128.121 14.2375C127.675 14.2375 127.341 14.089 127.044 13.8291C126.747 13.5692 126.635 13.2351 126.635 12.8638C126.635 12.6039 126.672 12.2326 126.784 11.7128L127.564 8.14844H123.441Z"
      fill="black"
    />
    <path
      d="M77.2284 17.3564H71.6191L82.0204 0.5L79.0486 17.3564H77.2284Z"
      fill="#FFEC00"
    />
    <path
      d="M79.5322 13.6436H85.1415L74.7402 30.5L77.712 13.6436H79.5322Z"
      fill="#FFEC00"
    />
  </svg>
)

export default WinITAlt
