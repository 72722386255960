import React from 'react'
import Helmet from 'react-helmet'
import {
  Hero,
  Container,
  Heading,
  Section,
  Columns,
} from 'react-bulma-components'
import AureolabLogo from 'assets/images/aureolab_logo.png'

import './styles.scss'

interface props {}

const About: React.FC<props> = () => {
  return (
    <div id="faq">
      <Helmet>
        <title>WinIT: ¿Quiénes Somos?</title>
      </Helmet>

      <Hero id="about-fold" color="dark">
        <Hero.Header />
        <Hero.Body>
          <Section>
            <Container breakpoint="desktop">
              <div className="inner">
                <Columns>
                  <Columns.Column
                    mobile={{ size: 12 }}
                    desktop={{ size: 8, offset: 2 }}
                    className="has-text-centered"
                  >
                    <Heading className="has-text-warning has-text-gradient is-size-1 is-size-2-mobile">
                      “Vinculamos developers y proyectos de forma justa”
                    </Heading>
                    <p className="is-size-4 is-size-5-mobile">
                      Queremos lograr la disrupción en el mercado TI a través
                      <br className="is-hidden-mobile" />
                      el uso de tecnologías.
                    </p>
                  </Columns.Column>
                </Columns>
              </div>
            </Container>
          </Section>
        </Hero.Body>
        <Hero.Footer></Hero.Footer>
      </Hero>
      <Section className="content-wrapper">
        <Container breakpoint="desktop">
          <div className="inner">
            <Columns>
              <Columns.Column mobile={{ size: 12 }} desktop={{ size: 6 }}>
                <div className="content">
                  <Heading size={2} className="is-size-4">
                    ¿Quiénes Somos?
                  </Heading>
                  <p>
                    Por experiencias personales, sabemos cómo funciona el
                    outsourcing TI, varios miembros de nuestro equipo han
                    trabajado de esta forma. En general, quienes ofrecen el
                    servicio son empresas de desarrollo tecnológico que cuentan
                    con un área de tercerización, destinando parte de su equipo
                    al servicio de otras compañías.
                  </p>
                  <p>
                    WinIT nace desde la experiencia y necesidad identificada por{' '}
                    <a
                      href="https://aureolab.cl"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Aureolab
                    </a>
                    . Como lo dice su nombre WinIT busca generar ganancias en el
                    mundo TI; es una plataforma que busca vincular oferta y
                    demanda a un precio justo, generando un mejor escenario para
                    ambas partes involucradas (empresa y developer), logrando
                    así que todos sus usuarios ganen.
                  </p>
                </div>
              </Columns.Column>
              <Columns.Column mobile={{ size: 12 }} desktop={{ size: 6 }}>
                <a href="https://aureolab.cl" target="_blank" rel="noreferrer">
                  <img src={AureolabLogo} alt="Aureolab" />
                </a>
              </Columns.Column>
            </Columns>
          </div>
        </Container>
      </Section>
    </div>
  )
}

export default About
