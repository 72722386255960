import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const CashFast: React.FC<Props> = () => (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.4 12.85C20.7 12.15 19.75 11.75 18.75 11.75C17.75 11.75 16.8 12.15 16.1 12.85C15.4 13.55 15 14.5 15 15.5C15 16.5 15.4 17.45 16.1 18.15C16.8 18.85 17.75 19.25 18.75 19.25C19.75 19.25 20.7 18.85 21.4 18.15C22.1 17.45 22.5 16.5 22.5 15.5C22.5 14.5 22.1 13.55 21.4 12.85ZM8.75 8V23H28.75V8H8.75ZM26.25 18C25.5875 18 24.95 18.2625 24.4875 18.7375C24.0125 19.2 23.75 19.8375 23.75 20.5H13.75C13.75 19.8375 13.4875 19.2 13.0125 18.7375C12.55 18.2625 11.9125 18 11.25 18V13C11.9125 13 12.55 12.7375 13.0125 12.2625C13.4875 11.8 13.75 11.1625 13.75 10.5H23.75C23.75 11.1625 24.0125 11.8 24.4875 12.2625C24.95 12.7375 25.5875 13 26.25 13V18ZM6.25 10.5H3.75C3.0625 10.5 2.5 9.9375 2.5 9.25C2.5 8.5625 3.0625 8 3.75 8H6.25V10.5ZM6.25 16.75H2.5C1.8125 16.75 1.25 16.1875 1.25 15.5C1.25 14.8125 1.8125 14.25 2.5 14.25H6.25V16.75ZM6.25 23H1.25C0.56 23 0 22.4375 0 21.75C0 21.0625 0.56 20.5 1.25 20.5H6.25V23Z" fill="url(#paint0_linear_1346_5780)"/>
    <defs>
      <linearGradient id="paint0_linear_1346_5780" x1="14.375" y1="8" x2="14.375" y2="23" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
    </defs>
  </svg>
)

export default CashFast
