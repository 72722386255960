import React from 'react'
import './styles.scss'
import ArrowUp from 'assets/icons/ArrowUp';

interface Props {
  title: string,
  showArrow: boolean,
  icon: React.ReactNode,
  reverseIcon: React.ReactNode,
  detail: string
}

const ServiceCard: React.FC<Props> = ({ title, icon, showArrow, detail, reverseIcon }) => {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <div className='service-text' style={{ display: 'flex', justifyContent: 'right', marginBottom: '120px' }}>
            <div style={{ fontSize: '36px' }}>{icon}</div>
          </div>
          <div className='service-text' style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-end', gap: '10px' }}>
            <div>{title}</div>
            {showArrow && <div style={{ fontSize: '36px' }}><ArrowUp /></div>}
          </div>
        </div>
        <div className="flip-card-back">
          <div className='service-text-detail' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '120px', alignItems: 'center' }}>
            <span>{title}</span>
            <div style={{ fontSize: '36px' }}>{reverseIcon}</div>
          </div>
          <div className='service-subtext-detail'>{detail}</div>
        </div>
      </div>
    </div>
  )
}

export default ServiceCard
