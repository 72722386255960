import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const Errors: React.FC<Props> = () => (
  <svg width="43" height="42" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" >
    <circle style={{ fill: '#D75A4A' }} cx="25" cy="25" r="25"/>
    <polyline style={{ fill:'none', stroke:'#FFFFFF', strokeWidth: '2', strokeLinecap: 'round', strokeMiterlimit: 10 }} points="16,34 25,25 34,16"/>
    <polyline style={{ fill:'none', stroke:'#FFFFFF', strokeWidth: '2', strokeLinecap: 'round', strokeMiterlimit: 10 }} points="16,16 25,25 34,34 "/>
  </svg>
)

export default Errors
