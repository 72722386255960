import { useState, useEffect } from 'react'
import validatejs from 'validate.js'

export interface CustomChangeEvent {
  target: {
    name: string
    value: any
    type?: any
  }
}

export interface UseFormReturns {
  payload: { [key: string]: any }
  editMode: boolean
  handleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | CustomChangeEvent
  ) => void
  resetForm: () => void
  validateAsync: () => Promise<boolean>
  validate: () => void
  validationErrors: {
    [key: string]: object
  } | null
  valid: boolean
  dirty: boolean
  pristine: boolean
}

/*
 * TO-DO: Enable bulk update
 */
export const useForm = (
  initialValues: { [key: string]: any },
  validations?: { [key: string]: any }
): UseFormReturns => {
  const [valid, setValid] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [pristine, setPristine] = useState(true)
  const [payload, setPayload] = useState<{ [key: string]: any }>({})
  const [editMode, setEditMode] = useState(false)
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: object
  }>({})

  useEffect(() => {
    if (editMode) {
      setValid(true)
    }
  }, [editMode])

  useEffect(() => {
    if (!pristine) {
      setValid(!validationErrors)
    }
  }, [validationErrors, pristine])

  useEffect(() => {
    if (initialValues) {
      setPayload({ ...initialValues })
      setEditMode(!!initialValues.id)
    }
  }, [initialValues])

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | CustomChangeEvent
  ) => {
    const { name, value } = e.target

    if (e.target.type === 'checkbox') {
      setPayload((prevState) => ({
        ...prevState,
        [name]: !prevState[name],
      }))
    } else {
      setPayload((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }

    setPristine(false)
    setDirty(true)

    if (validations) {
      const results = validatejs(
        { [name]: value },
        { [name]: validations[name] },
        {
          fullMessages: false,
        }
      )

      if (results) {
        if (Object.keys(validationErrors).length > 0) {
          setValidationErrors({ ...validationErrors, ...results })
        } else {
          setValidationErrors(results)
        }
      } else {
        // Since validations returned undefined
        // remove previous validation errors for this field 'name'
        if (validationErrors[name]) {
          const errorsCopy = { ...validationErrors }
          delete errorsCopy[name]
          setValidationErrors(errorsCopy)
        }
      }
    }
  }

  const validateAsync = () =>
    new Promise<boolean>((resolve, reject) => {
      const results =
        validatejs(payload, validations, {
          fullMessages: false,
        }) || null

      resolve(!results)
      setValidationErrors(results || {})
    })

  const validate = () => {
    const results =
      validatejs(payload, validations, {
        fullMessages: false,
      }) || null

    setValidationErrors(results || {})
  }

  const resetForm = () => {
    setPayload(initialValues)
    setValid(false)
    setPristine(true)
    setEditMode(false)
    setDirty(false)
    setValidationErrors({})
  }

  return {
    payload,
    editMode,
    handleChange,
    resetForm,
    validateAsync,
    validate,
    validationErrors:
      Object.keys(validationErrors).length > 0 ? validationErrors : null,
    valid,
    dirty,
    pristine,
  }
}
