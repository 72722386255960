/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { Navbar, Container } from 'react-bulma-components'
import { useLocation, NavLink } from 'react-router-dom'

import './styles.scss'
import { scrollTo } from 'helpers/scrollTo'

import WinIT from 'assets/icons/WinIT'
import WinITAlt from 'assets/icons/WinIT_Alt'

interface Props {}

const RenderNavbar: React.FC<Props> = () => {
  const { pathname, hash } = useLocation()

  const menuRef: any = useRef()
  const [viewMenu, setMenu] = useState(false)
  const [isHome, setHome] = useState(pathname === '/')
  const { y } = useWindowScroll()
  const toggleMenu = () => setMenu((prev) => !prev)

  useEffect(() => {
    if (pathname) {
      setHome(pathname === '/')
      setMenu(false)

      if (pathname === '/' && hash) {
        scrollTo(hash)
      }
    }
  }, [pathname])

  const onClickOutside = (e: any) => {
    if (menuRef.current && !menuRef.current!.contains(e.target) && viewMenu) {
      setMenu(false)
    }
  }

  const handleClick = (e: any) => {
    toggleMenu()
    scrollTo(e)
  }

  useEffect(() => {
    document.addEventListener('click', onClickOutside)
    return () => document.removeEventListener('click', onClickOutside)
  })

  return (
    <Navbar className={y >= 100 ? 'is-opaque' : 'is-transparent'}>
      <Container>
        <Navbar.Brand>
          {isHome ? (
            <Navbar.Item href="#" onClick={scrollTo}>
              {y >= 100 ? <WinITAlt /> : <WinIT />}
            </Navbar.Item>
          ) : (
            <Navbar.Item renderAs={NavLink} exact to="/">
              {y >= 100 ? <WinITAlt /> : <WinIT />}
            </Navbar.Item>
          )}
          <Navbar.Burger
            className={y >= 100 ? 'dark' : ''}
            onClick={toggleMenu}
          />
        </Navbar.Brand>
        <Navbar.Menu domRef={menuRef} className={viewMenu ? 'is-active' : ''}>
          <Navbar.Container>
            {isHome ? (
              <Navbar.Item onClick={handleClick} href="#como-funciona">
                ¿Cómo Funciona?
              </Navbar.Item>
            ) : (
              <Navbar.Item renderAs={NavLink} exact to="/#como-funciona">
                ¿Cómo funciona?
              </Navbar.Item>
            )}
          </Navbar.Container>

          <Navbar.Container align="right">
            {isHome ? (
              <>
                <Navbar.Item onClick={handleClick} href="#soy-empresa">
                  Soy Empresa
                </Navbar.Item>
                <Navbar.Item onClick={handleClick} href="#soy-developer">
                  Soy Developer
                </Navbar.Item>
                <Navbar.Item onClick={handleClick} href="#contactanos">
                  Contáctanos
                </Navbar.Item>
              </>
            ) : (
              <>
                <Navbar.Item renderAs={NavLink} exact to="/#soy-empresa">
                  Soy Empresa
                </Navbar.Item>
                <Navbar.Item renderAs={NavLink} exact to="/#soy-developer">
                  Soy Developer
                </Navbar.Item>
                <Navbar.Item renderAs={NavLink} exact to="/#contactanos">
                  Contáctanos
                </Navbar.Item>
              </>
            )}

            <Navbar.Item renderAs={NavLink} to="/preguntas-frecuentes">
              Preguntas Frecuentes
            </Navbar.Item>
            <Navbar.Item renderAs={NavLink} to="/quienes-somos">
              ¿Quiénes Somos?
            </Navbar.Item>
          </Navbar.Container>
        </Navbar.Menu>
      </Container>
    </Navbar>
  )
}

export default RenderNavbar
