import diagramaEmpresa1 from 'assets/images/diagrama_company_1.png'

const questions = [
  {
    title: 'Sobre WinIT',
    questions: [
      {
        question: '¿Quién es Mr. WinIT?',
        answer:
          '<p>Nuestro representante público es Mr. WinIT, un personaje que plasma la identidad de la marca y se encarga de las comunicaciones genéricas.</p>',
      },
      {
        question: 'Misión',
        answer:
          '<p>Nuestra misión es vincular la oferta y demanda de developer a un precio justo para todos los participantes.</p>',
      },
      {
        question: 'Visión',
        answer:
          '<p>Queremos lograr la disrupción en el mercado TI a través del uso de tecnologías.</p>',
      },
      {
        question: '¿Qué es el Outsourcing?',
        answer:
          '<p>El outsourcing es una forma de conseguir resultados sin mayores compromisos, una industria que crece de forma exponencial estos días. Cada vez vemos más empresas especializadas en algún negocio en particular y todo lo que escapa de su core business, se deriva a proveedores.</p><p>El mercado B2B, o servicios entre empresas, se da de forma bastante más sencilla que el tener que contratar personal para que desarrolle las tareas que se desean tercerizar. ¿Por qué? Porque de esta forma se ahorran costos fijos que se transforman en variables, los períodos de contratación tienen mayor flexibilidad, los resquicios legales son menores, no existen gastos de infraestructura y permite que la empresa se concentre en su negocio sin tener que ocuparse de temas que se encuentren fuera de sus capacidades.</p>',
      },
      {
        question: '¿Qué gana WinIT?',
        answer:
          '<p>Mientras más developers tengamos, más ganamos, mientras mejor esté calificada y clusterizada nuestra comunidad, más empresas solicitarán nuestros servicios, por eso estamos preocupados de crecer y capacitar a nuestra comunidad. Como mencionamos en un inicio, creemos que los cobros en la industria del outsourcing TI es injusto y excesivo; lo que nos llevó a desarrollar WinIT.</p><p>En WinIT la empresa dice cuánto quiere pagar y el developer cuánto quiere ganar, teniendo estos dos montos, nosotros buscamos hacer un match de valores quedándonos con un porcentaje menor entre el 10% y el 20% del cobro a la empresa, dependiendo del caso.</p><p>En palabras simples, le sumaremos entre un 10 y 20% al monto que solicita el developer y buscaremos hacer un match con ese número al presupuesto de la empresa, de modo que todas las partes queden conformes. Finalmente el ingreso de WinIT se da por sus clientes empresa, no developers.</p><p>Todo lo recaudado como porcentaje de comisión se usará para financiar el desarrollo de esta plataforma, mantener un equipo adecuado, generar alianzas y beneficios para nuestra comunidad y llegar cada día más lejos para poder entregar cada día más oportunidades de trabajo justo.</p>',
      },
    ],
  },
  {
    title: 'Empresas',
    questions: [
      {
        question: '¿Cómo inscribo a mi empresa?',
        answer:
          '<p>Las empresas que quieran contratar servicios de outsourcing a través de WinIT, deberán llenar el formulario de empresa en nuestro landing page,  donde se solicitará información de contacto del aplicante, información de la empresa y detalles sobre el proyecto a realizar para que puedas comenzar a armar tu equipo TI en base a los requerimientos técnicos del perfil que buscas.</p>',
      },
      {
        question: '¿Cuáles son las etapas del proceso?',
        answer: `<img src=${diagramaEmpresa1} alt="¿Cómo funciona WinIT?"/>`,
      },
      {
        question: '¿A quién le paga la empresa?',
        answer:
          'Toda empresa que busque y encuentre perfiles a través de WinIT, firmará un documento de contratación de servicios, por el cual WinIT emitirá facturas mensuales según lo acordado en el contrato.',
      },
    ],
  },
  {
    title: 'Developers',
    questions: [
      {
        question: '¿Cómo me inscribo?',
        answer:
          '<p>Para entrar a esta fantástica comunidad de WinIT, el developer debe llenar el formulario correspondiente en nuestro landing page. En este formulario el developer debe compartir la información solicitada, como el tipo de perfil de desarrollador que és, su nivel de experiencia, disponibilidad, intereses, expectativas de sueldo, entre otros.</p>',
      },
      {
        question: '¿Quién me paga?',
        answer:
          'Las remuneraciones destinadas al developers las realiza WinIT en las fechas pactadas antes del inicio del proyecto.',
      },
    ],
  },
]

export default questions
