import React from 'react'

interface Props {
    variant?: string
}

const Briefcase: React.FC<Props> = ({variant = 'primary'}) => {
    return (
        <div>
            {
                variant === "primary" ?
                    <svg color='black' width="54" height="50" viewBox="0 0 54 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.6708 0H32.1971C33.593 0 34.9317 0.554509 35.9187 1.54154C36.9058 2.52858 37.4603 3.86728 37.4603 5.26316V10.5263H47.9866C49.3825 10.5263 50.7212 11.0808 51.7082 12.0679C52.6952 13.0549 53.2497 14.3936 53.2497 15.7895V44.7368C53.2497 46.1327 52.6952 47.4714 51.7082 48.4585C50.7212 49.4455 49.3825 50 47.9866 50H5.88132C2.96027 50 0.618164 47.6316 0.618164 44.7368V15.7895C0.618164 12.8684 2.96027 10.5263 5.88132 10.5263H16.4076V5.26316C16.4076 2.34211 18.7497 0 21.6708 0ZM32.1971 10.5263V5.26316H21.6708V10.5263H32.1971Z" fill="#D0FE17" />
                    </svg> :
                    <svg width="53" height="50" viewBox="0 0 53 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.421 0H31.9474C33.3432 0 34.6819 0.554509 35.669 1.54154C36.656 2.52858 37.2105 3.86728 37.2105 5.26316V10.5263H47.7368C49.1327 10.5263 50.4714 11.0808 51.4584 12.0679C52.4455 13.0549 53 14.3936 53 15.7895V44.7368C53 46.1327 52.4455 47.4714 51.4584 48.4585C50.4714 49.4455 49.1327 50 47.7368 50H5.63157C2.71051 50 0.368408 47.6316 0.368408 44.7368V15.7895C0.368408 12.8684 2.71051 10.5263 5.63157 10.5263H16.1579V5.26316C16.1579 2.34211 18.5 0 21.421 0ZM31.9474 10.5263V5.26316H21.421V10.5263H31.9474Z" fill="url(#paint0_linear_1342_2900)" />
                        <defs>
                            <linearGradient id="paint0_linear_1342_2900" x1="26.6842" y1="0" x2="26.6842" y2="50" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#D0FE17" />
                                <stop offset="1" stopColor="#25C928" />
                            </linearGradient>
                        </defs>
                    </svg>
            }
        </div>
    )
}

export default Briefcase
