/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Footer, Container, Columns } from 'react-bulma-components'
import { useLocation, NavLink } from 'react-router-dom'

import Facebook from 'assets/icons/Facebook'
import LinkedIn from 'assets/icons/LinkedIn'
import WinIT from 'assets/icons/WinIT'
import Aureolab from 'assets/images/aureolab.png'
import Foundershub from 'assets/images/foundershub.png'
import { scrollTo } from 'helpers/scrollTo'
import Tiktok from 'assets/icons/Tiktok'
import Instagram from 'assets/icons/Instagram'

interface Props { }

const RenderFooter: React.FC<Props> = () => {
  const { pathname } = useLocation()
  const [isHome, setHome] = useState(pathname === '/')

  useEffect(() => {
    if (pathname) {
      setHome(pathname === '/')
    }
  }, [pathname])

  const handleClick = (e: any) => {
    scrollTo(e)
  }

  return (
    <Footer className="has-text-primary-light">
      <div id="pre-footer">
        <Container className="p-6 has-text-centered">
          <h5 className="is-size-4">¿Aún te quedan dudas?</h5>
          <p className="is-size-5">
            Si aún te quedan dudas puedes escribirnos a contacto@winit.work o
            visitar <br />
            nuestra página de{' '}
            <NavLink exact to="/preguntas-frecuentes">
              Preguntas Frecuentes
            </NavLink>
            .
          </p>
        </Container>
      </div>
      <div>
        <Container className="pb-4">
          <div className="has-text-centered">
            <WinIT />
            <ul className="mt-4 footer-menu">
              {isHome ? (
                <>
                  <li>
                    <a
                      onClick={handleClick}
                      href="#como-funciona"
                      className="has-text-primary-light"
                    >
                      ¿Cómo funciona?
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={handleClick}
                      href="#soy-empresa"
                      className="has-text-primary-light"
                    >
                      Soy Empresa
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={handleClick}
                      href="#soy-developer"
                      className="has-text-primary-light"
                    >
                      Soy Developer
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={handleClick}
                      href="#contactanos"
                      className="has-text-primary-light"
                    >
                      Contáctanos
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <NavLink
                      exact
                      to="/#como-funciona"
                      className="has-text-primary-light"
                    >
                      ¿Cómo funciona?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/#soy-empresa"
                      className="has-text-primary-light"
                    >
                      Soy Empresa
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/#soy-developer"
                      className="has-text-primary-light"
                    >
                      Soy Developer
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/#contactanos"
                      className="has-text-primary-light"
                    >
                      Contáctanos
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            <ul className="mt-4 footer-menu">
              <li>
                <NavLink
                  exact
                  to="/preguntas-frecuentes"
                  className="has-text-primary-light"
                >
                  Preguntas Frecuentes
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to="/quienes-somos"
                  className="has-text-primary-light"
                >
                  ¿Quiénes Somos?
                </NavLink>
              </li>
            </ul>
          </div>
          <Columns className="m-0 mt-6">
            <Columns.Column size={'one-third'}>
              <div className="flex-container powered-by">
                <div>Microsoft for startups</div>
                <div>
                  <a href="https://foundershub.startups.microsoft.com/signup">
                    <img
                      src={Foundershub}
                      width="150"
                      height="45"
                      alt="Aureolab"
                    />
                  </a>
                </div>
              </div>
            </Columns.Column>
            <Columns.Column size={'one-third'}>
              <div className="flex-container startups-by">
                <div>Powered by</div>
                <div>
                  <a href="https://aureolab.cl">
                    <img
                      src={Aureolab}
                      width="150"
                      height="45"
                      alt="Aureolab"
                    />
                  </a>
                </div>
              </div>
            </Columns.Column>
            <Columns.Column size={'one-third'}>
              <div className="flex-container socials has-text-right-desktop">
                <div>Síguenos en:</div>
                <div>
                  <a
                    href="https://www.facebook.com/WINIT-109842074785465"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook color='#fff' width={30} height={30} />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/winit.work/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram color='#fff' width={30} height={30} />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/company/winit-work"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedIn color='#fff' width={30} height={30} />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.tiktok.com/@winitwork"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tiktok color='#fff' width={30} height={30} />
                  </a>
                </div>
                {/* 
              <div>
                <a href="https://twitter.com" target="_blank" rel="noreferrer">
                  <Twitter />
                </a>
              </div>
              */}
              </div>
            </Columns.Column>
          </Columns>
        </Container>
      </div>
    </Footer>
  )
}

export default RenderFooter
