import React, { useState, useEffect } from 'react'
import axios from 'config/axios'
import Helmet from 'react-helmet'
import {
  Hero,
  Container,
  Heading,
  Section,
  Columns,
  Button,
} from 'react-bulma-components'
import { Link, useParams } from 'react-router-dom'
import LeftArrow from 'assets/icons/LeftArrow'
import CodeIcon from 'assets/icons/CodeBG'
import Twitter from 'assets/icons/Twitter'
import Facebook from 'assets/icons/Facebook'
import LinkedIn from 'assets/icons/LinkedIn'
import Whatsapp from 'assets/icons/Whatsapp'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share'
import moment from 'moment'
import { moneyFormatter } from 'helpers/moneyFormatter'
import { scrollTo } from 'helpers/scrollTo'
import DeveloperForm from 'components/forms/Developer'
import Loader from 'components/Loader'

import './styles.scss'
import FileInput from 'components/FileInput'

interface props { }

interface param {
  id: string
}

const Postulation: React.FC<props> = () => {
  const params: param = useParams();
  const [postulationData, setPostulationData] = useState<{
    type: { name: string },
    availabilityType: { name: string },
    workModality: { name: string },
    salary: number,
    createdAt: string,
    project: { company: { name: string, description: string, website: string, twitter: string, linkedin: string, facebook: string }, description: string, duration: string },
    tags: Array<{ name: string }>,
    seniority: { name: string },
    serviceType: { name: string },
  }>({
    type: { name: '' },
    availabilityType: { name: '' },
    workModality: { name: '' },
    salary: 0,
    createdAt: '',
    project: { company: { name: '', description: '', website: '', twitter: '', linkedin: '', facebook: '' }, description: '', duration: '' },
    tags: [],
    seniority: { name: '' },
    serviceType: { name: '' },
  });
  const [userData, setUserData] = useState<{
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    seniority: { _id: string },
    tags: { tag: { name: string, _id: string } }[],
    saasTags: { name: string, _id: string }[],
    salary: string,
    availability: { _id: string },
    availabilityType: { _id: string },
    coverLetter: string,
    profile: { _id: string },
    workModalities: { name: string, _id: string }[],
    nacionality: { _id: string },
    country: { _id: string },
  }>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    seniority: { _id: '' },
    tags: [],
    saasTags: [],
    salary: '',
    availability: { _id: '' },
    availabilityType: { _id: '' },
    coverLetter: '',
    profile: { _id: '' },
    workModalities: [],
    nacionality: { _id: '' },
    country: { _id: '' }
  });
  const [email, setEmail] = useState("")
  const [disabledSearch, setDisabledSearch] = useState(true)
  const [status, setStatus] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [lastEmail, setLastEmail] = useState("")

  useEffect(() => {
    profileById()
  }, [])

  const profileById = async () => {
    try {
      const response = await axios.get(`/profiles/${params.id}`)
      setPostulationData(response.data)
      setTimeout(() => { setIsLoadingData(false) }, 1000);
    } catch (error) {
      console.log(error)
    }
  }

  const searchUser = async () => {
    try {
      const response = await axios.get(`/users/email/data?email=${email}`)
      if (response.status) setStatus(response.status)
      setUserData(response.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setStatus(404)
    }
    setIsLoading(false)
  }

  const toForm = (e: any) => {
    scrollTo(e)
  }

  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleChange = (e: any) => {
    if (validateEmail(e.target.value)) {
      setDisabledSearch(false)
    } else {
      setDisabledSearch(true)
    }
    setEmail(e.target.value)
  }

  const handleSearch = () => {
    setIsLoading(true)
    setLastEmail(email)
    setUserData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      seniority: { _id: '' },
      tags: [],
      saasTags: [],
      salary: '',
      availability: { _id: '' },
      availabilityType: { _id: '' },
      coverLetter: '',
      profile: { _id: '' },
      workModalities: [],
      nacionality: { _id: '' },
      country: { _id: '' }
    })
    searchUser()
  }

  return (
    <div id="postulation">
      <Helmet>
        <title>WinIT: Postulación</title>
      </Helmet>
      <Hero id="postulation" color="dark">
        <Hero.Header />
        <Hero.Body style={{ paddingBottom: '0px' }}>
          <Section style={{ paddingBottom: '0px' }}>
            <Container breakpoint="desktop">
              {!isLoadingData ? (
                <div className="inner">
                  <Columns>
                    <Columns.Column size={12} >
                      <Link to="/">
                        <LeftArrow />
                      </Link>
                      <div className='header-botones'>
                        <div className='publicacion-fecha'>{postulationData && postulationData.createdAt ? 'Publicado el ' + moment(postulationData.createdAt).format('DD/MM/YYYY') : '-'} </div>
                        <a style={{ textDecoration: 'none' }} className='publicacion-boton-label' onClick={toForm} href="#form">
                          <div className='publicacion-boton'>
                            Postular a Empleo
                          </div>
                        </a>
                      </div>
                      <div>
                        <div className='estado'>Disponible</div>
                        <div className='titulo'>{postulationData.type && postulationData.type.name} {postulationData.seniority && postulationData.seniority.name}</div>
                        <div className='empresa'>{postulationData.project && postulationData.project.company && postulationData.project.company.name}</div>
                      </div>
                    </Columns.Column>
                    <Columns.Column size={12}>
                      <div className='detalles-no-tags'>
                        {postulationData.salary && (
                          <div style={{ paddingBlock: '15px' }}>
                            <div className='detalles-titulo'>Presupuesto</div>
                            <div className='detalles-contenido'>{moneyFormatter(postulationData.salary)} CLP</div>
                          </div>
                        )}
                        {postulationData.project && (
                          <div style={{ paddingBlock: '15px' }}>
                            <div className='detalles-titulo'>Tiempo Proyecto</div>
                            <div className='detalles-contenido'>{postulationData.project.duration}</div>
                          </div>
                        )}
                        {postulationData.workModality && (
                          <div style={{ paddingBlock: '15px' }}>
                            <div className='detalles-titulo'>Modalidad</div>
                            <div className='detalles-contenido'>{postulationData.workModality.name}</div>
                          </div>
                        )}
                        {postulationData.availabilityType && (
                          <div style={{ paddingBlock: '15px' }}>
                            <div className='detalles-titulo'>Jornada</div>
                            <div className='detalles-contenido'>{postulationData.availabilityType.name}</div>
                          </div>
                        )}
                        {postulationData.serviceType && (
                          <div style={{ paddingBlock: '15px' }}>
                            <div className='detalles-titulo'>Tipo de servicio</div>
                            <div className='detalles-contenido'>{postulationData.serviceType.name}</div>
                          </div>
                        )}
                      </div>
                      <div className='detalles'>
                        {postulationData.tags && (
                          <div style={{ paddingBlock: '15px' }}>
                            <div className='detalles-habilidades'>Habilidades</div>
                            <div className='tags-sections'>{postulationData.tags.map((item, index) => {
                              return (
                                <div className='habilidad-box' key={index}>
                                  <label className='habilidad-label'>{item.name}</label>
                                </div>
                              )
                            })}</div>
                          </div>
                        )}
                      </div>
                    </Columns.Column>
                    <Columns.Column size="three-quarters">
                      {postulationData.project && postulationData.project.description && (
                        <>
                          <div className='descripcion-titulo'>Descripción</div>
                          <div className='descripcion'><p className='parrafo'>{postulationData.project.description}</p></div>
                        </>
                      )}
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                      {postulationData.project && postulationData.project.company.description && (
                        <>
                          <div className='descripcion-titulo'>Descripción Empresa</div>
                          <div className='descripcion'><p className='parrafo'>{postulationData.project.company.description}</p></div>
                        </>
                      )}
                      <br />
                      {postulationData.project && postulationData.project.company.website && (
                        <>
                          <div className='descripcion-titulo'>Página Web</div>
                          <div className='descripcion'><p className='parrafo'><a href={postulationData.project.company.website}>{postulationData.project.company.website}</a></p></div>
                        </>
                      )}
                      <br />
                      {postulationData.project && postulationData.project.company.website && (
                        <>
                          <div className='descripcion-titulo'>Redes Sociales</div>
                          <div className='detalles-social'>
                            {postulationData.project.company.twitter && <a href={postulationData.project.company.twitter}><Twitter color='#02B5EF' width={20} height={20} /></a>}
                            {postulationData.project.company.facebook && <a href={postulationData.project.company.facebook}><Facebook color='#4464A2' width={20} height={20} /></a>}
                            {postulationData.project.company.linkedin && <a href={postulationData.project.company.linkedin}><LinkedIn color='#0286BD' width={20} height={20} /></a>}
                          </div>
                        </>
                      )}
                    </Columns.Column>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <hr style={{ color: '#C4C4C4', backgroundColor: '#C4C4C4', height: 0.1, width: '98%', marginBlock: '20px' }} />
                    </div>
                    <Columns.Column desktop={{ size: 12 }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'end' }}>
                        <div>
                          <div style={{ paddingBlock: '15px' }}>
                            <div className='detalles-titulo' style={{ marginBottom: '15px' }}>compartir anuncio</div>
                            <div className='detalles-contenidos'>
                              <TwitterShareButton
                                url={`https://${window.location.hostname}/postular/${params.id}`}
                              //hashtags={["", ""]}
                              //title="" texto al inicio del tweet
                              //via="" enlaza una cuenta de tw 
                              >
                                <Twitter color='#02B5EF' width={20} height={20} />
                              </TwitterShareButton>
                              <FacebookShareButton
                                url={`https://${window.location.hostname}/postular/${params.id}`}
                              //hashtag='#' 
                              >
                                <Facebook color='#4464A2' width={20} height={20} />
                              </FacebookShareButton>
                              <LinkedinShareButton
                                url={`https://${window.location.hostname}/postular/${params.id}`}
                              >
                                <LinkedIn color='#0286BD' width={20} height={20} />
                              </LinkedinShareButton>
                              <WhatsappShareButton
                                url={`https://${window.location.hostname}/postular/${params.id}`}
                              //title="" texto al inicio del msg
                              >
                                <Whatsapp color='#128C7E' width={20} height={20} />
                              </WhatsappShareButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Columns.Column>
                  </Columns>
                </div>
              ) : (
                <Loader className='' theme="dark" mode="inline" />
              )}
            </Container>
          </Section>
        </Hero.Body>
      </Hero>
      <Hero id="form" color="dark" size="halfheight">
        <Hero.Header />
        <Hero.Body style={{ padding: '0px' }}>
          <Container breakpoint="desktop">
            <Section style={{ paddingTop: '0px' }}>
              {!isLoadingData && (
                <>
                  <Heading>
                    <div className='titulo-postulacion'><CodeIcon color='#D0FE17' /> <label>completa tu postulación</label></div>
                    <label className='subtitulo-postulacion'>Ingresa tu mail para confirmar tu información. Si no tenemos tus datos no te preocupes, Sube tu CV para que Open AI llene el formulario por ti, así podrás realizar esta y otras postulaciones.</label>
                  </Heading>
                  <div style={{ width: '100%' }}>
                    <label className='input-label'>Correo</label>
                    <div className='search-section'>
                      <input className='input-email' type="email" name="email" value={email} onChange={(e) => handleChange(e)} />
                      <Button
                        color="warning"
                        renderAs="a"
                        size="medium"
                        onClick={() => handleSearch()}
                        disabled={disabledSearch}
                      >
                        Buscar
                      </Button>
                    </div>
                    {status === 200 && (
                      <div className='mensaje exito'>
                        Tu correo ya se encuentra en nuestra base de datos. Puedes postular directamente a este anuncio.
                      </div>
                    )}
                    {status === 404 && (
                      <div className='mensaje'>
                        El correo no encuentra en nuestra base de datos. Completa el formulario para continuar con la postulación.
                      </div>
                    )}
                    {status !== 0 && isLoading === false && (
                      <div>{
                        status === 200 ?
                          <DeveloperForm
                            initialValues={{
                              country: userData.country._id || '',
                              firstName: userData.firstName || '',
                              lastName: userData.lastName || '',
                              email: lastEmail,
                              phone: userData.phone || '',
                              seniority: userData.seniority._id || '',
                              abilities: userData.tags ? userData.tags.map((tag: any) => ({ id: tag.tag._id, text: tag.tag.name })) : [],
                              saasTags: userData.saasTags ? userData.saasTags.map((item: any) => ({ id: item._id, text: item.name })) : [],
                              salary: userData.salary || '',
                              availability: userData.availability._id || '',
                              availabilityType: userData.availabilityType._id || '',
                              coverLetter: userData.coverLetter || '',
                              profile: userData.profile._id || '',
                              workModality: userData.workModalities ? userData.workModalities.map((item: any) => ({ value: item._id, label: item.name })) : [],
                              nacionality: userData.nacionality._id || '',
                            }}
                            isPostulation={true}
                            idPostulation={params.id}
                            hiddenEmail={true}
                          /> :
                          <FileInput
                            initialValues={{
                              country: userData.country._id || '',
                              firstName: userData.firstName || '',
                              lastName: userData.lastName || '',
                              email: lastEmail,
                              phone: userData.phone || '',
                              seniority: userData.seniority._id || '',
                              abilities: userData.tags ? userData.tags.map((tag: any) => ({ id: tag.tag._id, text: tag.tag.name })) : [],
                              saasTags: userData.saasTags ? userData.saasTags.map((item: any) => ({ id: item._id, text: item.name })) : [],
                              salary: userData.salary || '',
                              availability: userData.availability._id || '',
                              availabilityType: userData.availabilityType._id || '',
                              coverLetter: userData.coverLetter || '',
                              profile: userData.profile._id || '',
                              workModality: userData.workModalities.map((item: any) => ({ value: item._id, label: item.name })) || [],
                              nacionality: userData.nacionality._id || '',
                            }}
                            isPostulation={true}
                            idPostulation={params.id}
                          />
                      }</div>
                    )}
                  </div>
                </>
              )}
            </Section>
          </Container>
        </Hero.Body>
      </Hero>
    </div>
  )
}

export default Postulation
