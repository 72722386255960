/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import { Form } from 'react-bulma-components'
import './styles.scss'

import ErrorList from 'components/ErrorList'
const { Label } = Form

export interface Tag {
  id: string
  text: string
}

interface Props {
  name: string
  label?: string
  id?: string
  suggestions: Tag[]
  initialTags?: Tag[]
  onChange: (tags: Tag[]) => void
  helpText?: string
  placeholder?: string
  errors?: { [key: string]: any } | null
}

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

const TagInput: React.FC<Props> = ({
  name,
  helpText,
  id,
  label,
  suggestions,
  onChange,
  placeholder,
  initialTags,
  errors,
}) => {
  const [initialized, setInitialized] = useState(false)
  const [tags, setTags] = useState<Tag[]>([])
  const [isActive, setActive] = useState<Boolean>(false)
  const toogleActive = () => setActive((prev) => !prev)

  useEffect(() => {
    setInitialized(true)
    if(initialTags) setTags(initialTags || [])
  }, [])

  useEffect(() => {
    if (initialized) {
      onChange(tags)
    }
  }, [tags])

  useEffect(() => {
    if (initialized && initialTags && initialTags.length > 0) {
      setTags(initialTags)
    }
  }, [initialTags])

  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i))
  }

  const handleAddition = (tag: Tag) => {
    if(tag.id.toLowerCase() !== tag.text.toLowerCase()) {
    setTags([...tags, tag])
    } 
  }

  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = tags.slice()

    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)

    // re-render
    setTags(newTags)
  }

  const handleTagClick = (index: number) => {
    console.log('The tag at index ' + index + ' was clicked')
  }

  return (
    <>
      {label && (
        <>
          <Label htmlFor={id}>{label}</Label>
          {helpText && <p className="is-size-7 mb-3">{helpText}</p>}
        </>
      )}
      <div className={`input-tag-wrapper ${isActive ? 'is-active' : ''}`}>
        {tags && initialTags &&(<ReactTags
          tags={tags}
          allowDragDrop={false}
          autofocus={false}
          suggestions={suggestions}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          autocomplete
          inputFieldPosition="top"
          handleInputFocus={toogleActive}
          handleInputBlur={toogleActive}
          minQueryLength={1}
          placeholder={placeholder}
        />)}

        {errors && errors[name] && <ErrorList errors={errors[name]} />}
      </div>
    </>
  )
}

export default TagInput
