import React from 'react'

interface Props {
}

const CompanySection: React.FC<Props> = () => (
  <svg width="100%" height="540" viewBox="0 0 551 540" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1283_3476)">
      <path d="M257.325 326.336V317.892L338.414 371.657C345.439 376.316 354.631 376.069 361.396 371.041L393.784 346.97V358.403C393.784 360.007 393.015 361.514 391.716 362.454L359.298 385.914C357.593 387.148 355.541 387.813 353.435 387.813H349.713C347.839 387.813 346.003 387.286 344.414 386.293L262.026 334.816C259.102 332.989 257.325 329.784 257.325 326.336Z" fill="#3F3F3F"/>
      <path d="M358.031 376.861C354.741 379.054 350.469 379.103 347.129 376.986L257.652 320.261C256.434 319.489 256.409 317.721 257.604 316.914L298.62 289.241C300.255 288.138 302.386 288.099 304.06 289.141L393.402 344.788C396.487 346.709 396.557 351.176 393.532 353.192L358.031 376.861Z" fill="url(#paint0_linear_1283_3476)"/>
      <path d="M469.344 212.187L490.772 202.295V389.834C490.772 393.211 489.067 396.36 486.239 398.207L469.344 409.239V212.187Z" fill="#323232"/>
      <path d="M357.696 361.873V329.168L370.101 337.626V354.543L357.696 361.873Z" fill="#999999"/>
      <path d="M357.696 361.873L287.775 319.582V286.313L357.696 328.605V361.873Z" fill="url(#paint1_linear_1283_3476)"/>
      <path d="M211.967 42.0936C217.485 40.1427 223.615 40.785 228.707 43.8476L489.662 200.778C490.792 201.457 490.999 203.027 490.08 203.949L474.419 219.662L195.862 47.7876L211.967 42.0936Z" fill="url(#paint2_linear_1283_3476)"/>
      <path d="M196.007 56.0745C196.045 52.1849 200.314 49.8262 203.627 51.8648L472.603 217.381C474.082 218.291 474.983 219.903 474.983 221.64V404.916C474.983 408.803 470.741 411.204 467.409 409.202L196.624 246.576C195.104 245.664 194.181 244.014 194.198 242.241L196.007 56.0745Z" fill="url(#paint3_linear_1283_3476)"/>
      <path d="M202.065 67.6844C202.065 66.1282 203.764 65.1682 205.097 65.9712L462.738 221.189C463.338 221.551 463.705 222.201 463.705 222.902V391.046C463.705 392.6 462.009 393.56 460.676 392.76L203.036 238.118C202.433 237.756 202.065 237.105 202.065 236.403V67.6844Z" fill="url(#paint4_linear_1283_3476)"/>
      <path d="M224.968 209.282C258.107 253.171 263.883 306.161 239.88 324.284C215.877 342.408 169.555 321.522 136.416 277.633C103.277 233.745 95.8715 183.474 119.874 165.35C143.877 147.226 191.83 165.394 224.968 209.282Z" fill="url(#paint5_linear_1283_3476)"/>
      <ellipse cx="173.964" cy="248.541" rx="99.6183" ry="54.7925" transform="rotate(56.727 173.964 248.541)" fill="#D0FE17"/>
      <ellipse cx="174.008" cy="251.081" rx="89.0379" ry="48.8208" transform="rotate(56.727 174.008 251.081)" fill="#FDFDFD"/>
      <ellipse cx="174.657" cy="255.649" rx="71.2333" ry="39.0583" transform="rotate(56.727 174.657 255.649)" fill="#D0FE17"/>
      <ellipse cx="174.144" cy="255.262" rx="53.0369" ry="29.0809" transform="rotate(56.727 174.144 255.262)" fill="white"/>
      <ellipse cx="173.402" cy="255.377" rx="32.3073" ry="17.7146" transform="rotate(56.727 173.402 255.377)" fill="url(#paint6_linear_1283_3476)"/>
      <path d="M101.5 402.076L181.903 448.849L285.5 388.933L203.937 341L101.5 402.076Z" fill="#C4C4C4"/>
      <path d="M113.87 422.563L166.441 342.159L272.744 368.058L218.24 445.369L113.87 422.563Z" fill="#E5E5E5"/>
      <path d="M311.5 440.5V435.5L366 464.5L416 434V439.5L366 470L311.5 440.5Z" fill="#1A901C"/>
      <path d="M366 465L311.5 435.5L364 403L416 434L366 465Z" fill="url(#paint7_linear_1283_3476)"/>
      <path d="M311.5 445.5V440.5L366 469.5L416 439V444.5L366 475L311.5 445.5Z" fill="#BAEE0C"/>
      <path d="M311.5 450.5V445.5L366 474.5L416 444.5V449.5L366 480L311.5 450.5Z" fill="#E5E5E5"/>
      <path d="M84 325L52.5 341.5L63 321.5L40 318.5L75.5 296.5L91 300L170.514 255.121C171.625 254.494 173.032 254.852 173.708 255.934C174.417 257.068 174.055 258.562 172.906 259.246L91 308L84 325Z" fill="url(#paint8_linear_1283_3476)"/>
      <path d="M279.5 177L295.5 185.5V276L279.5 267V177Z" fill="url(#paint9_linear_1283_3476)"/>
      <path d="M309.5 214L325.5 220.679V292L309.5 284.714V214Z" fill="url(#paint10_linear_1283_3476)"/>
      <path d="M339.5 257L355.5 261.423V310L339.5 301.837V257Z" fill="url(#paint11_linear_1283_3476)"/>
      <path d="M369.5 245L385.5 252.026V328L369.5 319.388V245Z" fill="url(#paint12_linear_1283_3476)"/>
      <path d="M399.5 234L415.5 243.628V345L399.5 335.939V234Z" fill="url(#paint13_linear_1283_3476)"/>
      <path d="M429.5 225L445.5 236.969V362L429.5 352.735V225Z" fill="url(#paint14_linear_1283_3476)"/>
      <path d="M195.521 374.281V364.281C183.521 365.115 162.321 370.681 161.521 386.281C160.721 401.881 182.854 407.448 195.521 408.281V397.781C189.521 397.615 177.521 395.081 177.521 386.281C177.521 377.481 189.521 374.615 195.521 374.281Z" fill="#C4C4C4"/>
      <path d="M202.021 374.281V364.281C213.5 365 218 367.5 224.021 371.281C219.5 374.281 216.5 375.5 212.521 377.781C211.635 378.289 205.188 374.781 202.021 374.281Z" fill="#BAEE0C"/>
      <path d="M218.521 383.781L217.521 380.781L229.521 374.281C230.188 375.115 232.118 376.733 233.021 377.781C234.5 379.5 235.854 382.115 236.521 383.781H218.521Z" fill="#BAEE0C"/>
      <path d="M214.521 393.281C216.121 393.281 217.854 389.948 218.521 388.281H236.521C234.121 396.681 229.854 400.448 228.021 401.281C222.854 398.615 212.921 393.281 214.521 393.281Z" fill="#BAEE0C"/>
      <path d="M202.021 408.281L201.5 398C203.9 398 208.354 396.781 210.021 395.781L221.021 403.281C218.854 404.448 214 406.5 211.521 407.281C209.201 408.013 204 409 202.021 408.281Z" fill="#BAEE0C"/>
    </g>
    <defs>
      <filter id="filter0_d_1283_3476" x="0" y="0.983459" width="550.772" height="539.017" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="10" dy="10"/>
        <feGaussianBlur stdDeviation="25"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.196078 0 0 0 0 0.196078 0 0 0 0 0.196078 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1283_3476"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1283_3476" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_1283_3476" x1="304.866" y1="360.625" x2="350.191" y2="306.888" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C4C4C4"/>
        <stop offset="0.0001" stopColor="#DEDEDE"/>
        <stop offset="1" stopColor="#999999"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1283_3476" x1="322.735" y1="286.313" x2="322.735" y2="361.873" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DDD6D6"/>
        <stop offset="1" stopColor="#999999"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1283_3476" x1="356.318" y1="87.7298" x2="327.843" y2="167.022" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4F4F4F"/>
        <stop offset="1" stopColor="#1E1E1E"/>
      </linearGradient>
      <linearGradient id="paint3_linear_1283_3476" x1="334.577" y1="47.2285" x2="334.577" y2="413.751" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D9D9D9"/>
        <stop offset="1" stopColor="#999999"/>
      </linearGradient>
      <linearGradient id="paint4_linear_1283_3476" x1="229.5" y1="115" x2="421.5" y2="361" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4F4F4F"/>
        <stop offset="1" stopColor="#1D1D1B"/>
      </linearGradient>
      <linearGradient id="paint5_linear_1283_3476" x1="121.047" y1="164.465" x2="241.053" y2="323.399" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E4FF78"/>
        <stop offset="1" stopColor="#B1E30B"/>
      </linearGradient>
      <linearGradient id="paint6_linear_1283_3476" x1="173.402" y1="237.663" x2="173.402" y2="273.092" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#BAEE0C"/>
      </linearGradient>
      <linearGradient id="paint7_linear_1283_3476" x1="363.75" y1="403" x2="363.75" y2="465" gradientUnits="userSpaceOnUse">
        <stop stopColor="#74FB77"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
      <linearGradient id="paint8_linear_1283_3476" x1="61" y1="322" x2="175.5" y2="258" gradientUnits="userSpaceOnUse">
        <stop stopColor="#626060"/>
        <stop offset="0.534748" stopColor="#867E7E"/>
        <stop offset="1" stopColor="#575756"/>
      </linearGradient>
      <linearGradient id="paint9_linear_1283_3476" x1="287.5" y1="177" x2="287.5" y2="276" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
      <linearGradient id="paint10_linear_1283_3476" x1="317.5" y1="214" x2="317.5" y2="292" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
      <linearGradient id="paint11_linear_1283_3476" x1="347.5" y1="257" x2="347.5" y2="310" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
      <linearGradient id="paint12_linear_1283_3476" x1="377.5" y1="245" x2="377.5" y2="328" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
      <linearGradient id="paint13_linear_1283_3476" x1="407.5" y1="234" x2="407.5" y2="345" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
      <linearGradient id="paint14_linear_1283_3476" x1="437.5" y1="225" x2="437.5" y2="362" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
    </defs>
  </svg>
)

export default CompanySection
