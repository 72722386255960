import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const Success: React.FC<Props> = () => (
  <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M21.5001 0.166992C10.0001 0.166992 0.666748 9.50033 0.666748 21.0003C0.666748 32.5003 10.0001 41.8337 21.5001 41.8337C33.0001 41.8337 42.3334 32.5003 42.3334 21.0003C42.3334 9.50033 33.0001 0.166992 21.5001 0.166992ZM17.3334 31.417L6.91675 21.0003L9.85425 18.0628L17.3334 25.5212L33.1459 9.70866L36.0834 12.667L17.3334 31.417Z" 
      fill="#25C928"
    />
  </svg>
)

export default Success
