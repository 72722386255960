import React from 'react'
import Collapsible from 'react-collapsible'
import { Columns } from 'react-bulma-components'

import ChevronUp from 'assets/icons/ChevronUp'
import ChevronDown from 'assets/icons/ChevronDown'
import './style.scss'

interface Props {
  children: React.ReactChild | React.ReactChild[]
  className?: string
  title?: string
  subTitle?: string
  alt?: boolean
  open?: boolean
}

interface TriggerBodyProps {
  children: React.ReactElement
  title?: string
  subTitle?: string
}

const { Column } = Columns

const TriggerBody: React.FC<TriggerBodyProps> = ({
  title,
  subTitle,
  children,
}) => (
  <Columns multiline={false} breakpoint="mobile">
    <Column
      mobile={{
        size: 9,
      }}
    >
      <span
        style={{ lineHeight: '36px' }}
        className="font-bold has-text-warning"
      >
        {title} {subTitle && '| '}
      </span>
      {subTitle && <span>{subTitle}</span>}
    </Column>
    <Column mobile={{ size: 3 }}>
      <div className="has-text-right">{children}</div>
    </Column>
  </Columns>
)

const Trigger: React.FC<Partial<TriggerBodyProps>> = ({ title, subTitle }) => {
  return (
    <TriggerBody title={title} subTitle={subTitle}>
      <ChevronUp color="#FCF200" />
    </TriggerBody>
  )
}

const TriggerWhenOpen: React.FC<Partial<TriggerBodyProps>> = ({
  title,
  subTitle,
}) => {
  return (
    <TriggerBody title={title} subTitle={subTitle}>
      <ChevronDown color="#FCF200" />
    </TriggerBody>
  )
}

const RenderCollapsible: React.FC<Props> = ({
  alt,
  title,
  subTitle,
  children,
  open,
  ...props
}) => {
  return (
    <div className={`my-collapsible ${alt && 'alt'}`}>
      <Collapsible
        trigger={<Trigger title={title} subTitle={subTitle} />}
        triggerWhenOpen={<TriggerWhenOpen title={title} subTitle={subTitle} />}
        triggerTagName="div"
        transitionTime={200}
        open={open}
        {...props}
      >
        {children}
      </Collapsible>
    </div>
  )
}

export default RenderCollapsible
