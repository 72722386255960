import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const Facebook: React.FC<Props> = ({
  color,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 30 30" fill="none">
    <path
      d="M28 15.5C28 8.6 22.4 3 15.5 3C8.6 3 3 8.6 3 15.5C3 21.55 7.3 26.5875 13 27.75V19.25H10.5V15.5H13V12.375C13 9.9625 14.9625 8 17.375 8H20.5V11.75H18C17.3125 11.75 16.75 12.3125 16.75 13V15.5H20.5V19.25H16.75V27.9375C23.0625 27.3125 28 21.9875 28 15.5Z"
      fill={color}
    />
  </svg>
)

export default Facebook
