import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const CodeBG: React.FC<Props> = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="10" fill={color}/>
    <path d="M20.9271 10.625L22.9687 11.0417L19.0729 29.375L17.0312 28.9583L20.9271 10.625ZM27.9062 20L24.1666 16.2604V13.3125L30.8541 20L24.1666 26.6771V23.7292L27.9062 20ZM9.14581 20L15.8333 13.3125V16.2604L12.0937 20L15.8333 23.7292V26.6771L9.14581 20Z" fill="black"/>
    <defs>
      <linearGradient id="paint0_linear_963_3930" x1="42.6054" y1="4.76101e-06" x2="-6.32651" y2="18.976" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#FFEC00"/>
      </linearGradient>
    </defs>
  </svg>
)

export default CodeBG
