import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const Expand: React.FC<Props> = () => (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5 20.5H21.775L20.7625 19.4875C21.875 18 22.5 16.125 22.5 14.25C22.5 9.4125 18.5875 5.5 13.75 5.5C11.875 5.5 10 6.125 8.4875 7.25C4.625 10.15 3.8375 15.6375 6.7375 19.5C9.6375 23.3625 15.125 24.15 18.9875 21.25L20 22.2625V23L26.25 29.25L28.75 26.75L22.5 20.5ZM13.75 20.5C10.3 20.5 7.5 17.7 7.5 14.25C7.5 10.8 10.3 8 13.75 8C17.2 8 20 10.8 20 14.25C20 17.7 17.2 20.5 13.75 20.5ZM3.75 8L1.25 10.5V1.75H10L7.5 4.25H3.75V8ZM26.25 1.75V10.5L23.75 8V4.25H20L17.5 1.75H26.25ZM7.5 24.25L10 26.75H1.25V18L3.75 20.5V24.25H7.5Z" fill="url(#paint0_linear_1346_5772)"/>
    <defs>
      <linearGradient id="paint0_linear_1346_5772" x1="15" y1="1.75" x2="15" y2="29.25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
    </defs>
  </svg>
)

export default Expand
