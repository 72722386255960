import React, { useState } from 'react'
import { Columns, Button } from 'react-bulma-components'
import Modal from 'components/Modal'

import TextField from 'components/TextField'
import TextArea from 'components/TextArea'

import './styles.scss'
import validations from './validations'
import { useFbq } from 'hooks/useFbq'
import { useForm } from 'hooks/useForm'
import axios from 'config/axios'

export interface InitialValues {
  name: string
  email: string
  message: string
}

interface Props {
  initialValues: InitialValues
}

const { Column } = Columns

const ContactForm: React.FC<Props> = ({ initialValues }) => {
  const [feedback, setFeedback] = useState({
    title: '',
    message: '',
  })
  const isFbqReady = useFbq()

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)


  const hideModal = () => {
    setShowModal(false)
    setFeedback({
      title: '',
      message: '',
    })
  }

  const { payload, handleChange, validationErrors } = useForm(
    initialValues,
    validations
  )

  const submit = async () => {
    console.log(payload)
    setLoading(true)
    const endpoint = '/contact'

    try {
      await axios.post(endpoint, payload)

      if (process.env.NODE_ENV === 'production' && isFbqReady) {
        window.fbq('trackCustom', 'FormSubmit', { form: 'company' })
      }

      setFeedback({
        title: 'Gracias',
        message: 'Solicitud enviada, ¡ya nos contáctaremos contigo!',
      })
      setShowModal(true)
    } catch (error) {
      let errorMessage = 'Algo salió mal, inténtalo de nuevo más tarde'

      setFeedback({
        title: 'Oops...',
        message: errorMessage,
      })
      setShowModal(true)
    }

    setLoading(false)
  }

  return (
    <>
      {showModal && (
        <Modal
          title={feedback.title}
          message={feedback.message}
          onClose={hideModal}
        />
      )}

      <div className='contact-card'>
        <Columns>
          <Column size="half">
            <h1 className='contact-title'>Comunícate con nosotros</h1><br />
            <p>¿Quieres saber más sobre nuestros servicios de TI?</p>
            <p>Envianos un mensaje y te responderemos lo antes posible.</p>
          </Column>
          <Column size="half">
            <TextField
              label="Nombre"
              // placeholder="xxx"
              id="name"
              name="name"
              value={payload.name}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
            <TextField
              label="Correo"
              // placeholder="xxx@xxx.xx"
              id="email"
              name="email"
              value={payload.email}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
            <TextArea
              label="Mensaje"
              helpText="¿Qué andas buscando? ¿Qué quieres realizar?"
              id="message"
              name="message"
              value={payload.message}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
            <Button.Group align="right">
              <Button
                className='contact-button'
                loading={loading}
                disabled={loading}
                onClick={submit}
              >
                Envíar
              </Button>
            </Button.Group>
          </Column>
        </Columns>
      </div>
    </>
  )
}

export default ContactForm