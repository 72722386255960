import React from 'react'

interface Props {
}

const ArrowUp: React.FC<Props> = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.49961 20C9.49961 20.8284 10.1712 21.5 10.9996 21.5H11.4996C12.328 21.5 12.9996 20.8284 12.9996 20V8.41422C12.9996 7.96876 13.5382 7.74568 13.8532 8.06066L18.3139 12.5214C18.8997 13.1072 19.8495 13.1072 20.4353 12.5214L20.7961 12.1607C21.3818 11.5749 21.3818 10.6251 20.7961 10.0393L12.3103 1.55355C11.7245 0.967768 10.7747 0.96777 10.1889 1.55355L1.70316 10.0393C1.11738 10.6251 1.11738 11.5749 1.70316 12.1607L2.05672 11.8071L1.70316 12.1607L2.06395 12.5214C2.64974 13.1072 3.59948 13.1072 4.18527 12.5214L8.64606 8.06066C8.96104 7.74568 9.49961 7.96876 9.49961 8.41422V20Z" fill="#1E1E1E" stroke="#1E1E1E" />
    </svg>
)

export default ArrowUp
