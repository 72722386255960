import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const Whatsapp: React.FC<Props> = ({
  color,
  width,
  height,
}) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M10.04 0C4.58005 0 0.130049 4.45 0.130049 9.91C0.130049 11.66 0.590049 13.36 1.45005 14.86L0.0500488 20L5.30005 18.62C6.75005 19.41 8.38005 19.83 10.04 19.83C15.5 19.83 19.9501 15.38 19.9501 9.92C19.9501 7.27 18.92 4.78 17.05 2.91C15.18 1.03 12.69 0 10.04 0ZM10.05 1.67C12.25 1.67 14.31 2.53 15.87 4.09C17.42 5.65 18.2801 7.72 18.2801 9.92C18.2801 14.46 14.58 18.15 10.04 18.15C8.56005 18.15 7.11005 17.76 5.85005 17L5.55005 16.83L2.43005 17.65L3.26005 14.61L3.06005 14.29C2.24005 13 1.80005 11.47 1.80005 9.91C1.81005 5.37 5.50005 1.67 10.05 1.67ZM6.53005 5.33C6.37005 5.33 6.10005 5.39 5.87005 5.64C5.65005 5.89 5.00005 6.5 5.00005 7.71C5.00005 8.93 5.89005 10.1 6.00005 10.27C6.14005 10.44 7.76005 12.94 10.25 14C10.84 14.27 11.3 14.42 11.66 14.53C12.25 14.72 12.79 14.69 13.22 14.63C13.7 14.56 14.68 14.03 14.89 13.45C15.1 12.87 15.1 12.38 15.04 12.27C14.97 12.17 14.81 12.11 14.56 12C14.31 11.86 13.09 11.26 12.87 11.18C12.64 11.1 12.5 11.06 12.31 11.3C12.15 11.55 11.67 12.11 11.53 12.27C11.38 12.44 11.24 12.46 11 12.34C10.74 12.21 9.94005 11.95 9.00005 11.11C8.26005 10.45 7.77005 9.64 7.62005 9.39C7.50005 9.15 7.61005 9 7.73005 8.89C7.84005 8.78 8.00005 8.6 8.10005 8.45C8.23005 8.31 8.27005 8.2 8.35005 8.04C8.43005 7.87 8.39005 7.73 8.33005 7.61C8.27005 7.5 7.77005 6.26 7.56005 5.77C7.36005 5.29 7.16005 5.35 7.00005 5.34C6.86005 5.34 6.70005 5.33 6.53005 5.33Z" 
        fill={color}
      />
    </svg>
)

export default Whatsapp