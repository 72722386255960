import $ from 'jquery'

export const scrollTo = (event: any) => {
  // event.preventDefault()
  const hash =
    typeof event === 'string'
      ? event
      : event.currentTarget.attributes.href.nodeValue
  let targetPos = 0

  if (hash && hash !== '#') {
    const $target = $(hash)
    targetPos = $target.offset()!.top - 45
  }

  $('html, body').animate(
    {
      scrollTop: targetPos,
    },
    800,
    function () {
      // window.location.hash = hash !== '#' ? hash : ''
    }
  )
}
