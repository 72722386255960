import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
  strokeWidth?: string | number
}

const ChevronUp: React.FC<Props> = ({
  color = '#999',
  width = 28,
  height = 28,
  strokeWidth = 1,
}) => (
  <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
    <path
      d="M8 17L14 11L20 17"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
)

export default ChevronUp
