import { useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'

import Home from 'pages/Home'
import FAQ from 'pages/FAQ'
import About from 'pages/About'

import TagManager from 'react-gtm-module'
import Postulation from 'pages/Postulation'

const init = (gtmId: any) => {
  const tagManagerArgs = {
    gtmId,
    dataLayerName: 'dataLayer',
  };

  TagManager.initialize(tagManagerArgs);
};

const App = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    init("GTM-TVDQHNF");
  }, []);


  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/preguntas-frecuentes">
          <FAQ />
        </Route>
        <Route exact path="/quienes-somos">
          <About />
        </Route>
        <Route exact path="/postular/:id">
          <Postulation />
        </Route>
      </Switch>
      <Footer />
    </>
  )
}

export default App
