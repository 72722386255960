import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const Finance: React.FC<Props> = () => (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 21.125L3.75 24.8V14.25H7.5M13.75 18.825L11.7875 17.15L10 18.8V9.25H13.75M20 16.75L16.25 20.5V4.25H20M23.5125 16.5125L21.25 14.25H27.5V20.5L25.2625 18.2625L16.25 27.2L11.9125 23.425L7.1875 28H3.75L11.8375 20.075L16.25 23.8" fill="url(#paint0_linear_1346_5788)"/>
    <defs>
      <linearGradient id="paint0_linear_1346_5788" x1="15.625" y1="4.25" x2="15.625" y2="28" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
    </defs>
  </svg>
)

export default Finance
