import { lettersOnlyRg } from '../../../constants'

const validations = {
  name: {
    presence: { allowEmpty: false, message: 'Su nombre es requerido' },
    format: {
      pattern: lettersOnlyRg,
      message: 'Por favor utilice únicamente letras.',
    },
  },
  email: {
    email: {
      allowEmpty: false,
      message: 'Por favor ingrese un correo válido',
    },
    presence: {
      allowEmpty: false,
      message: 'Su correo electrónico es requerido',
    },
  },
  message: {
    presence: {
      allowEmpty: false,
      message: 'Por favor cuéntanos un poco sobre tu proyecto',
    },
  }
}

export default validations
