import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const Instagram: React.FC<Props> = ({
  color = '#fff',
  width,
  height,
}) => (
  < svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path
      d="M5.8 0.941895H14.2C17.4 0.941895 20 3.54189 20 6.74189V15.1419C20 16.6802 19.3889 18.1554 18.3012 19.2431C17.2135 20.3308 15.7383 20.9419 14.2 20.9419H5.8C2.6 20.9419 0 18.3419 0 15.1419V6.74189C0 5.20364 0.61107 3.72839 1.69878 2.64068C2.78649 1.55296 4.26174 0.941895 5.8 0.941895ZM5.6 2.94189C4.64522 2.94189 3.72955 3.32118 3.05442 3.99631C2.37928 4.67144 2 5.58712 2 6.54189V15.3419C2 17.3319 3.61 18.9419 5.6 18.9419H14.4C15.3548 18.9419 16.2705 18.5626 16.9456 17.8875C17.6207 17.2123 18 16.2967 18 15.3419V6.54189C18 4.55189 16.39 2.94189 14.4 2.94189H5.6ZM15.25 4.44189C15.5815 4.44189 15.8995 4.57359 16.1339 4.80801C16.3683 5.04243 16.5 5.36037 16.5 5.69189C16.5 6.02342 16.3683 6.34136 16.1339 6.57578C15.8995 6.8102 15.5815 6.94189 15.25 6.94189C14.9185 6.94189 14.6005 6.8102 14.3661 6.57578C14.1317 6.34136 14 6.02342 14 5.69189C14 5.36037 14.1317 5.04243 14.3661 4.80801C14.6005 4.57359 14.9185 4.44189 15.25 4.44189ZM10 5.94189C11.3261 5.94189 12.5979 6.46868 13.5355 7.40636C14.4732 8.34404 15 9.61581 15 10.9419C15 12.268 14.4732 13.5397 13.5355 14.4774C12.5979 15.4151 11.3261 15.9419 10 15.9419C8.67392 15.9419 7.40215 15.4151 6.46447 14.4774C5.52678 13.5397 5 12.268 5 10.9419C5 9.61581 5.52678 8.34404 6.46447 7.40636C7.40215 6.46868 8.67392 5.94189 10 5.94189ZM10 7.94189C9.20435 7.94189 8.44129 8.25796 7.87868 8.82057C7.31607 9.38318 7 10.1462 7 10.9419C7 11.7375 7.31607 12.5006 7.87868 13.0632C8.44129 13.6258 9.20435 13.9419 10 13.9419C10.7956 13.9419 11.5587 13.6258 12.1213 13.0632C12.6839 12.5006 13 11.7375 13 10.9419C13 10.1462 12.6839 9.38318 12.1213 8.82057C11.5587 8.25796 10.7956 7.94189 10 7.94189Z"
      fill={color} />
  </svg >
)

export default Instagram


