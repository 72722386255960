import React from 'react'

interface Props {
  errors: string[]
}

const ErrorList: React.FC<Props> = ({ errors }) => {
  return (
    <ul className="mt-2">
      {errors.map((error: string) => (
        <li className="is-size-7 has-text-warning" key={error}>
          {error}
        </li>
      ))}
    </ul>
  )
}

export default ErrorList
