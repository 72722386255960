/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bulma-components'
import ReactSelect from 'react-select'
import { CustomChangeEvent } from 'hooks/useForm'

import ErrorList from 'components/ErrorList'
const { Field, Label, Control, Select } = Form

interface Option {
  text: string
  value: string
}

interface OptionMulti {
  label: string
  value: string
}

interface Props {
  name: string
  value: string | string[] | Option | OptionMulti
  options: Option[] | string[]
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement> | CustomChangeEvent
  ) => void
  label?: string
  helpText?: string
  placeholder?: string
  type?: string
  id?: string
  errors?: { [key: string]: any } | null
  disabled?: boolean
  multiple?: boolean
}

const customStyles = {
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontFamily: '"Inter", sans-serif',
    color: '#ffffff',
    fontSize: 12,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 30,
    backgroundColor: '#1E1E1E;',
    fontFamily: '"JetBrains Mono", monospace',
    borderColor: '#ffffff',
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#000000',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#000000',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#000000',
    border: '1px solid #ffec00',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: '0 8px',
    color: '#ffec00',
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: '0 8px',
    color: '#ffec00',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    height: 22,
    borderRadius: 0,
    color: '#000000',
    backgroundColor: '#ffec00',
    fontFamily: 'Inter, sans-serif',
    '& > div': {
      color: '#000000',
      fontSize: 12,
    },
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: '#000000',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#ffec00',
      color: '#000000',
    },
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    paddingBottom: 1,
  }),
}

const RenderSelect: React.FC<Props> = ({
  name,
  value,
  onChange,
  label,
  helpText,
  placeholder = '',
  id,
  errors,
  disabled,
  options,
  multiple = false,
}) => {
  const [selectOptions, setSelectOptions] = useState<Option[]>([])
  const [selectMultiOptions, setMultiOptions] = useState<OptionMulti[]>([])

  useEffect(() => {
    if (options.length > 0) {
      const values = options.map((item) =>
        typeof item === 'string' ? { value: item, text: item } : item
      )

      const valuesForMulti = options.map((item) =>
        typeof item === 'string'
          ? { value: item, label: item }
          : { value: item.value, label: item.text }
      )

      setSelectOptions(values)
      setMultiOptions(valuesForMulti)
    }
  }, [options])

  const handleMultiChange = (selectedOption: any) => {
    if (onChange) {
      onChange({
        target: {
          name,
          value: selectedOption,
        },
      })
    }
  }

  return (
    <Field>
      {label && (
        <>
          <Label htmlFor={id}>{label}</Label>
          {helpText && <p className="is-size-7 mb-3">{helpText}</p>}
        </>
      )}
      {multiple ? (
        <ReactSelect
          value={value}
          placeholder={placeholder}
          isMulti
          name={name}
          options={selectMultiOptions}
          onChange={handleMultiChange}
          styles={customStyles}
          noOptionsMessage={() => 'No hay opciones'}
          className='is-fullwidth'
        />
      ) : (
        <Control>
          <Select
            value={value}
            onChange={onChange}
            id={id}
            name={name}
            disabled={disabled}
            className="is-fullwidth"
          >
            {placeholder && (
              <option value="" disabled selected>
                {placeholder}
              </option>
            )}
            {selectOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.text}
              </option>
            ))}
          </Select>
        </Control>
      )}
      {errors && errors[name] && <ErrorList errors={errors[name]} />}
    </Field>
  )
}

export default RenderSelect
