import React from 'react'

interface Props {
}

const ArrowDown: React.FC<Props> = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M15.6666 0.666664L12.3333 0.666664L12.3333 20.6667L3.16662 11.5L0.799952 13.8667L14 27.0667L27.2 13.8667L24.8333 11.5L15.6666 20.6667L15.6666 0.666664Z" 
      fill="#FDFDFD"
    />
  </svg>
)

export default ArrowDown
