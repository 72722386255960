import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const Wheel: React.FC<Props> = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0001 13.5C9.07183 13.5 8.18159 13.1313 7.52521 12.4749C6.86883 11.8185 6.50008 10.9283 6.50008 10C6.50008 9.07174 6.86883 8.1815 7.52521 7.52513C8.18159 6.86875 9.07183 6.5 10.0001 6.5C10.9283 6.5 11.8186 6.86875 12.475 7.52513C13.1313 8.1815 13.5001 9.07174 13.5001 10C13.5001 10.9283 13.1313 11.8185 12.475 12.4749C11.8186 13.1313 10.9283 13.5 10.0001 13.5ZM17.4301 10.97C17.4701 10.65 17.5001 10.33 17.5001 10C17.5001 9.67 17.4701 9.34 17.4301 9L19.5401 7.37C19.7301 7.22 19.7801 6.95 19.6601 6.73L17.6601 3.27C17.5401 3.05 17.2701 2.96 17.0501 3.05L14.5601 4.05C14.0401 3.66 13.5001 3.32 12.8701 3.07L12.5001 0.42C12.4601 0.18 12.2501 0 12.0001 0H8.00008C7.75008 0 7.54008 0.18 7.50008 0.42L7.13008 3.07C6.50008 3.32 5.96008 3.66 5.44008 4.05L2.95008 3.05C2.73008 2.96 2.46008 3.05 2.34008 3.27L0.340084 6.73C0.210084 6.95 0.270084 7.22 0.460084 7.37L2.57008 9C2.53008 9.34 2.50008 9.67 2.50008 10C2.50008 10.33 2.53008 10.65 2.57008 10.97L0.460084 12.63C0.270084 12.78 0.210084 13.05 0.340084 13.27L2.34008 16.73C2.46008 16.95 2.73008 17.03 2.95008 16.95L5.44008 15.94C5.96008 16.34 6.50008 16.68 7.13008 16.93L7.50008 19.58C7.54008 19.82 7.75008 20 8.00008 20H12.0001C12.2501 20 12.4601 19.82 12.5001 19.58L12.8701 16.93C13.5001 16.67 14.0401 16.34 14.5601 15.94L17.0501 16.95C17.2701 17.03 17.5401 16.95 17.6601 16.73L19.6601 13.27C19.7801 13.05 19.7301 12.78 19.5401 12.63L17.4301 10.97Z" fill="#25C928"/>
  </svg>
)

export default Wheel
