/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Columns, Button } from 'react-bulma-components'
import axios from 'config/axios'

import CodeIcon from 'assets/icons/CodeBG'
import TextField from 'components/TextField'
import TextArea from 'components/TextArea'
import MoneyField from 'components/MoneyField'
import Select from 'components/Select'
import TagInput, { Tag } from 'components/TagInput'
import Modal from 'components/Modal'
import { useForm } from 'hooks/useForm'
import { useFbq } from 'hooks/useFbq'
import validations from './validations'
import validationsPostulation from './validationsPostulation'
import PostulationModal from 'components/PostulationModal'

const { Column } = Columns

export interface InitialValues {
  firstName: string
  lastName: string
  email: string
  country: string
  nacionality: string
  phone: string
  seniority: string
  abilities: Tag[]
  saasTags: Tag[]
  salary: string
  availability: string
  availabilityType: string
  coverLetter: string
  profile: string
  workModality: { label: string, value: string }[]
}

export interface Country {
  code: string
  dial_code: string
  name_en: string
  name_es: string
  _id: string
}

interface Props {
  initialValues: InitialValues
  isPostulation: boolean
  idPostulation: string
  hiddenEmail?: boolean
  file?: any
}

const DeveloperForm: React.FC<Props> = ({ initialValues, isPostulation, idPostulation, hiddenEmail = false, file }) => {
  const [feedback, setFeedback] = useState({
    title: '',
    message: '',
  })
  const isFbqReady = useFbq()
  const [suggestions, setSuggestions] = useState([])
  const [suggestionsSaas, setSuggestionsSaas] = useState([])
  const [availabilities, setAvailabilities] = useState([])
  const [availabilityTypes, setAvailabilityTypes] = useState([])
  const [seniorities, setSeniorities] = useState([])
  const [devProfiles, setDevProfiles] = useState([])
  const [modalities, setModalities] = useState([])
  const [countries, setCountries] = useState([])
  const [nacionalities, setNacionalities] = useState([])
  const [countryCode, setCountryCode] = useState('+56')
  const [countryOptions, setCountryOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalPostulation, setShowModalPostulation] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const hideModal = () => {
    setShowModal(false)
    setFeedback({
      title: '',
      message: '',
    })
  }

  const hideModalPostulation = () => {
    setShowModalPostulation(false)
    setFeedback({
      title: '',
      message: '',
    })
  }

  const { payload, handleChange, validateAsync, validationErrors } = useForm(
    initialValues,
    isPostulation ? validationsPostulation : validations
  )

  useEffect(() => {
    fetchAbilities()
    fetchAvailabilities()
    fetchAvailabilityTypes()
    fetchSeniorities()
    fetchDevProfiles()
    fetchWorkModalities()
    fetchCountries()
    fetchAbilitiesSaaS()
  }, [])

  useEffect(() => {
    const selectedCountry: Country = countries.find(
      (country: Country) => country._id === payload.country
    )!

    if (selectedCountry) {
      setCountryCode(selectedCountry.dial_code)
    }
  }, [payload.country])

  const fetchAbilitiesSaaS = async () => {
    const response = await axios.get('/saastags')

    setSuggestionsSaas(
      response.data.map((saasTag: any) => ({
        id: saasTag._id,
        text: saasTag.name,
      }))
    )
  }

  const fetchAbilities = async () => {
    const response = await axios.get('/tags')

    setSuggestions(
      response.data.map((tag: any) => ({ id: tag._id, text: tag.name }))
    )
  }

  const fetchAvailabilities = async () => {
    const response = await axios.get('/availabilities')

    setAvailabilities(
      response.data.map((availability: any) => ({
        value: availability._id,
        text: availability.name,
      }))
    )
  }

  const fetchAvailabilityTypes = async () => {
    const response = await axios.get('/availabilitytypes')

    setAvailabilityTypes(
      response.data.map((availabilityType: any) => ({
        value: availabilityType._id,
        text: availabilityType.name,
      }))
    )
  }

  const fetchSeniorities = async () => {
    const response = await axios.get('/seniorities')

    setSeniorities(
      response.data.map((seniority: any) => ({
        value: seniority._id,
        text: seniority.name,
      }))
    )
  }

  const fetchDevProfiles = async () => {
    const response = await axios.get('/profiletypes')

    setDevProfiles(
      response.data.map((profile: any) => ({
        value: profile._id,
        text: profile.name,
      }))
    )
  }

  const fetchWorkModalities = async () => {
    const response = await axios.get('/workmodalities')

    setModalities(
      response.data.map((modality: any) => ({
        value: modality._id,
        text: modality.name,
      }))
    )
  }

  const fetchCountries = async () => {
    const response = await axios.get('/countries')

    setCountries(response.data)
    setNacionalities(response.data.map((country: any) => ({
      value: country._id,
      text: country.name_es,
    })))
    setCountryOptions(
      response.data.map((country: any) => ({
        value: country._id,
        text: country.name_es,
      }))
    )
  }

  const jsonToFormData = (payload: any, formData: any) => {
    Object.keys(payload).forEach(key => {
      if (Array.isArray(payload[key])) {
        const array = payload[key]
        Object.keys(array).forEach(subkey => formData.append(key, array[subkey]));
      } else {
        formData.append(key, payload[key])
      }
    });
  }

  const submit = async (): Promise<void> => {
    setLoading(true)
    const valid = await validateAsync()
    const endpoint = '/users?sendEmail=true'

    if (valid) {

      const data: any = {
        ...payload,
        salary: parseInt(payload.salary.replace(/\D/g, '')),
        tags: payload.abilities.map((ability: Tag) => ability.id),
        saasTags: payload.saasTags.map((saasTag: Tag) => saasTag.id),
        workModalities: payload.workModality.map(
          (modality: { value: string }) => modality.value
        ),
      }

      delete data.abilities
      delete data.workModality

      const formData = new FormData();
      jsonToFormData(data, formData)

      if (file) {
        formData.append('cv', file);
      }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
      try {
        await axios.post(endpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          }
        })

        if (process.env.NODE_ENV === 'production' && isFbqReady) {
          window.fbq('trackCustom', 'FormSubmit', { form: 'developer' })
        }

        setFeedback({
          title: 'Bienvenido',
          message: 'Ya guardamos tus datos, ¡eres parte de la comunidad WinIT!',
        })
        setShowModal(true)
      } catch (error: any) {
        if (error.response && error.response.data) {
          const { message } = error.response.data
          let errorMessage = 'Algo salió mal, inténtalo de nuevo más tarde'

          if (
            message.includes(
              'E11000 duplicate key error collection: winit.users index: phone_1 dup key'
            )
          ) {
            errorMessage = 'El número telefónico ya ha sido registrado.'
          }

          if (
            message.includes(
              'E11000 duplicate key error collection: winit.users index: email_1 dup key'
            )
          ) {
            errorMessage = 'El correo electrónico ya ha sido registrado.'
          }

          setFeedback({
            title: 'Oops...',
            message: errorMessage,
          })
          setShowModal(true)
        }
      }
    }

    setLoading(false)
  }

  const submitPostulation = async (): Promise<void> => {
    setLoading(true)
    const valid = await validateAsync()
    const endpoint = '/postulant'

    if (valid) {
      const data: any = {
        profile: idPostulation,
        developer: {
          ...payload,
          salary: parseInt(payload.salary.replace(/\D/g, '')),
          tags: payload.abilities.map((ability: Tag) => ability.id),
          saasTags: payload.saasTags.map((saasTag: Tag) => saasTag.id),
          workModalities: payload.workModality.map(
            (modality: { value: string }) => modality.value
          ),
        }
      }
      delete data.developer.abilities
      delete data.developer.workModality
      console.log(data)

      const formData = new FormData();

      if (file) {
        formData.append('cv', file);
      }

      try {
        const response = await axios.post(endpoint, data);

        if (file) {
          console.log(response)
          if (response?.status === 201) {
            await axios.post(`/users/${response.data.developer}/cv/public`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
              }
            })
          }
        }
        console.log(response);
        setErrorMessage('')
        setShowModalPostulation(true)
      } catch (error: any) {
        if (error.response && error.response.data) {
          const { message } = error.response.data
          setErrorMessage(message)
          setShowModalPostulation(true)
        }
      }
    }

    setLoading(false)
  }

  const handleAbilities = (tags: Tag[]) => {
    handleChange({
      target: {
        name: 'abilities',
        value: tags,
      },
    })
  }

  const handleAbilitiesSaas = (saasTags: Tag[]) => {
    handleChange({
      target: {
        name: 'saasTags',
        value: saasTags,
      },
    })
  }

  return (
    <>
      {showModal && (
        <Modal
          title={feedback.title}
          message={feedback.message}
          onClose={hideModal}
        />
      )}

      {showModalPostulation && (
        <PostulationModal
          message={errorMessage}
          onClose={hideModalPostulation}
        />
      )}
      <div>
        {isPostulation === false && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '30px' }}>
            <CodeIcon width={50} height={50} color='url(#paint0_linear_963_3930)' />
            <p className="is-uppercase" style={{ fontWeight: 700, fontSize: '36px' }}>Developer</p>
          </div>
        )}
        <Columns>
          <Column size={hiddenEmail ? 'half' : 'one-third'}>
            <TextField
              label="Nombres"
              placeholder="Nombres"
              id="firstName"
              name="firstName"
              value={payload.firstName}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>
          <Column size={hiddenEmail ? 'half' : 'one-third'}>
            <TextField
              label="Apellidos"
              placeholder="Apellido Apellido"
              id="lastName"
              name="lastName"
              value={payload.lastName}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>
          {!hiddenEmail && (
            <Column size="one-third">
              <TextField
                label="Correo"
                placeholder="email@domain.com"
                id="email"
                name="email"
                value={payload.email}
                onChange={handleChange}
                errors={validationErrors}
                disabled={loading}
              />
            </Column>
          )}
        </Columns>

        <Columns>
          <Column size="one-third">
            <Select
              label="Nacionalidad"
              placeholder="Seleccionar"
              id="nacionality"
              name="nacionality"
              value={payload.nacionality}
              options={nacionalities}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>

          <Column size="one-third">
            <Select
              label="País de residencia"
              placeholder="Seleccionar"
              id="country"
              name="country"
              value={payload.country}
              options={countryOptions}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>

          <Column size="one-third">
            <TextField
              label="Teléfono"
              placeholder={countryCode}
              id="phone"
              name="phone"
              value={payload.phone}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>
        </Columns>

        <Columns>
          <Column size="one-third">
            <Select
              label="Perfil"
              placeholder="Seleccionar Perfil"
              id="profile"
              name="profile"
              value={payload.profile}
              options={devProfiles}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>
          <Column size="one-third">
            <Select
              label="Seniority"
              placeholder="Seleccionar Experencia"
              id="seniority"
              name="seniority"
              value={payload.seniority}
              options={seniorities}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>
          <Column size="one-third">
            <MoneyField
              label="¿Cuánto quieres ganar?"
              placeholder="Líquido mensual en CLP"
              id="salary"
              name="salary"
              value={payload.salary}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>
        </Columns>

        <TagInput
          placeholder="Menciona qué tecnologías, herramientas, lenguajes, frameworks manejas."
          id="abilities"
          name="abilities"
          label="Habilidades Técnicas"
          suggestions={suggestions}
          onChange={handleAbilities}
          errors={validationErrors}
          initialTags={initialValues.abilities}
        />

        <TagInput
          placeholder="Menciona los servicios con los que estás familiarizado."
          id="saasTags"
          name="saasTags"
          label="Experiencia con SaaS"
          suggestions={suggestionsSaas}
          onChange={handleAbilitiesSaas}
          errors={validationErrors}
          initialTags={initialValues.saasTags.length > 0 ? initialValues.saasTags : []}
        />

        <Columns>
          <Column size="one-third">
            <Select
              label="Disponibilidad"
              placeholder="Seleccionar"
              id="availability"
              name="availability"
              value={payload.availability}
              options={availabilities}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>
          <Column size="one-third">
            <Select
              label="Tipo de Jornada"
              placeholder="Seleccionar"
              id="availabilityType"
              name="availabilityType"
              value={payload.availabilityType}
              options={availabilityTypes}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>
          <Column size="one-third">
            <Select
              label="Modalidad de Trabajo"
              placeholder="Selecciona las que apliquen"
              id="workModality"
              name="workModality"
              multiple
              value={payload.workModality}
              options={modalities}
              onChange={handleChange}
              errors={validationErrors}
              disabled={loading}
            />
          </Column>
        </Columns>
        <TextArea
          label="Cuéntanos sobre ti"
          helpText="Queremos saber un poco más de ti ¿De dónde eres? ¿Qué tipo de proyectos quisieras realizar? ¿Cuál ha sido tu proyecto favorito?"
          id="coverLetter"
          name="coverLetter"
          value={payload.coverLetter}
          onChange={handleChange}
          errors={validationErrors}
          disabled={loading}
        />
        <Button.Group align="right">
          <Button
            loading={loading}
            disabled={loading}
            onClick={isPostulation ? submitPostulation : submit}
            color="warning"
          >
            Envíar
          </Button>
        </Button.Group>
      </div>
    </>
  )
}

export default DeveloperForm
