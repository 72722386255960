import Errors from 'assets/icons/Errors'
import Success from 'assets/icons/Success'
import React from 'react'
import { Modal, Button } from 'react-bulma-components'
import { Link } from 'react-router-dom'

import './styles.scss'

interface Props {
  message: string
  onClose: () => void
}

const { Card } = Modal
const { Body } = Card

const PostulationModal: React.FC<Props> = ({ message, onClose }) => {
  return (
    <Modal show={true}>
      <Card style={{ borderRadius: '5px' }}>
        <Body className="has-text-white" style={{ backgroundColor: '#FDFDFD' }}>
          <div style={{ textAlign: 'center', paddingTop: '15px'}}>
            { message === '' ? <Success /> : <Errors /> }
            <div className='mensaje-label'>
              { message === '' ? (<>Tu postulación ha sido enviada con éxito. <br/> Revisa tu bandeja de entrada para saber sobre los pasos a seguir.</>) : message }
            </div>
            <Link to='/'>
              <Button onClick={onClose} color="black" style={{ marginBottom: '15px'}}>
                Entendido
              </Button>
            </Link>
          </div>
        </Body>
      </Card>
    </Modal>
  )
}

export default PostulationModal
