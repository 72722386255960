import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const UploadIcon: React.FC<Props> = () => (
  <svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25 0H5C2.25 0 0.0250006 2.25 0.0250006 5L0 45C0 47.75 2.225 50 4.975 50H35C37.75 50 40 47.75 40 45V15L25 0ZM35 45H5V5H22.5V17.5H35V45ZM10 32.525L13.525 36.05L17.5 32.1V42.5H22.5V32.1L26.475 36.075L30 32.525L20.025 22.5L10 32.525Z" fill="url(#paint0_linear_1408_269)"/>
    <defs>
      <linearGradient id="paint0_linear_1408_269" x1="20" y1="0" x2="20" y2="50" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0FE17"/>
        <stop offset="1" stopColor="#25C928"/>
      </linearGradient>
    </defs>
  </svg>
)

export default UploadIcon
