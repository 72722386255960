import { lettersOnlyRg } from '../../../constants'

const validationsPostulation = {
  firstName: {
    presence: { allowEmpty: false, message: 'Su nombre es requerido' },
    format: {
      pattern: lettersOnlyRg,
      message: 'Por favor utilice únicamente letras.',
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: 'Sus apellidos es requerido' },
    format: {
      pattern: lettersOnlyRg,
      message: 'Por favor utilice únicamente letras.',
    },
  },
  abilities: {
    presence: {
      allowEmpty: false,
      message: 'Por favor cuéntanos sobre tus habilidades',
    },
  },
  nacionality: {
    presence: {
      allowEmpty: false,
      message: 'Por favor selecciona tu nacionalidad',
    },
  },
  country: {
    presence: {
      allowEmpty: false,
      message: 'Por favor selecciona tu país de residencia',
    },
  },
  email: {
    email: {
      allowEmpty: false,
      message: 'Por favor ingrese un correo válido',
    },
    presence: { allowEmpty: false, message: 'Correo electrónico es requerido' },
  },
  phone: {
    presence: {
      allowEmpty: false,
      message: 'Su número telefónico es requerido',
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: 'Por favor utilice únicamente números',
    }
  },
  seniority: {
    presence: { allowEmpty: false, message: 'Requerido' },
  },
  salary: {
    presence: {
      allowEmpty: false,
      message: 'Requerido',
    },
  },
  availability: {
    presence: {
      allowEmpty: false,
      message: 'Requerido',
    },
  },
  availabilityType: {
    presence: {
      allowEmpty: false,
      message: 'Requerido',
    },
  },
  coverLetter: {
    presence: { allowEmpty: false, message: 'Requerido' },
  },
  workModality: {
    presence: {
      allowEmpty: false,
      message: 'Requerido',
    },
    length: {
      minimum: 1,
      tooShort: 'Requerido',
    },
  },
}

export default validationsPostulation
