import React from 'react'
import { Modal, Button } from 'react-bulma-components'

interface Props {
  title: string
  message: string
  onClose: () => void
}

const { Card } = Modal
const { Header, Title, Body, Footer } = Card

const RenderModal: React.FC<Props> = ({ title, message, onClose }) => {
  return (
    <Modal show={true}>
      <Card>
        <Header>
          <Title>{title}</Title>
        </Header>

        <Body className="has-text-white">{message}</Body>

        <Footer>
          <Button onClick={onClose} color="danger">
            Cerrar
          </Button>
        </Footer>
      </Card>
    </Modal>
  )
}

export default RenderModal
