import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const LinkedIn: React.FC<Props> = ({
  color = '#fff',
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 30 30" fill="none">
    <path
      d="M24.3333 3C25.0406 3 25.7189 3.28095 26.219 3.78105C26.719 4.28115 27 4.95942 27 5.66667V24.3333C27 25.0406 26.719 25.7189 26.219 26.219C25.7189 26.719 25.0406 27 24.3333 27H5.66667C4.95942 27 4.28115 26.719 3.78105 26.219C3.28095 25.7189 3 25.0406 3 24.3333V5.66667C3 4.95942 3.28095 4.28115 3.78105 3.78105C4.28115 3.28095 4.95942 3 5.66667 3H24.3333ZM23.6667 23.6667V16.6C23.6667 15.4472 23.2087 14.3416 22.3936 13.5264C21.5784 12.7113 20.4728 12.2533 19.32 12.2533C18.1867 12.2533 16.8667 12.9467 16.2267 13.9867V12.5067H12.5067V23.6667H16.2267V17.0933C16.2267 16.0667 17.0533 15.2267 18.08 15.2267C18.5751 15.2267 19.0499 15.4233 19.3999 15.7734C19.75 16.1235 19.9467 16.5983 19.9467 17.0933V23.6667H23.6667ZM8.17333 10.4133C8.76742 10.4133 9.33717 10.1773 9.75725 9.75725C10.1773 9.33717 10.4133 8.76742 10.4133 8.17333C10.4133 6.93333 9.41333 5.92 8.17333 5.92C7.57571 5.92 7.00257 6.1574 6.57999 6.57999C6.1574 7.00257 5.92 7.57571 5.92 8.17333C5.92 9.41333 6.93333 10.4133 8.17333 10.4133ZM10.0267 23.6667V12.5067H6.33333V23.6667H10.0267Z"
      fill={color}
    />
  </svg>
)

export default LinkedIn
