import React from 'react'
import Helmet from 'react-helmet'
import {
  Hero,
  Container,
  Heading,
  Section,
  Columns,
} from 'react-bulma-components'
import Collapsible from 'components/Collapsible'

import './styles.scss'
import questions from './data'

interface props { }

const FAQ: React.FC<props> = () => {
  return (
    <div id="faq">
      <Helmet>
        <title>WinIT: Preguntas Frecuentes</title>
      </Helmet>

      <Hero id="faq-fold" color="dark">
        <Hero.Header />
        <Hero.Body>
          <Section>
            <Container breakpoint="desktop">
              <div className="inner">
                <Columns>
                  <Columns.Column mobile={{ size: 12 }} desktop={{ size: 5 }}>
                    <p className="is-size-4 is-size-5-mobile">
                      Preguntas Frecuentes
                    </p>
                    <Heading className="has-text-warning has-text-gradient is-size-1 is-size-2-mobile">
                      WinIT responde a tus preguntas
                    </Heading>
                  </Columns.Column>
                </Columns>
              </div>
            </Container>
          </Section>
        </Hero.Body>
        <Hero.Footer></Hero.Footer>
      </Hero>

      <Section className="content-wrapper" size="medium">
        <Container breakpoint="desktop" max>
          <div className="inner">
            <Columns>
              {questions.map(section => (
                <>
                  <Columns.Column mobile={{ size: 12 }} desktop={{ size: 4 }}>
                    <div className="content">
                      <Heading size={2} className="is-size-4">
                        {section.title}
                      </Heading>
                    </div>
                  </Columns.Column>
                  <Columns.Column mobile={{ size: 12 }} desktop={{ size: 8 }}>
                    <div className="content">
                      {section.questions.map(item => (

                        <Collapsible title={item.question}>
                          <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                        </Collapsible>
                      ))}
                    </div>
                  </Columns.Column>
                </>
              ))}
            </Columns>
          </div>
        </Container>
      </Section>
    </div>
  )
}

export default FAQ
