import React from 'react'

import './styles.scss'

interface Props {
  icon: React.ReactNode
  title: string
  subtitle: string
  body: string
}

const DeveloperCard: React.FC<Props> = ({ icon, title, subtitle, body }) => {
  return (
    <div className='box-card'>
      { icon }
      <div className='title-card'>
        { title } 
        {subtitle !== '' && (
          <>
            <br/> { subtitle }
          </>
        )}
      </div>
      <div className='body-card'>
        { body }
      </div>
    </div>
  )
}

export default DeveloperCard
