import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

const Delete: React.FC<Props> = () => (
  <svg style={{ cursor: 'pointer' }} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.96 7L14.5 12.54V14H13.04L7.5 8.46L1.96 14H0.5V12.54L6.04 7L0.5 1.46V0H1.96L7.5 5.54L13.04 0H14.5V1.46L8.96 7Z" fill="#FDFDFD"/>
  </svg>
)

export default Delete
