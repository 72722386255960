import React from 'react'

interface Props {
  variant?: string
}

const PersonFind: React.FC<Props> = ({ variant = 'primary' }) => (
  variant === 'primary' ?
    <svg width="68" height="60" viewBox="0 0 68 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.2513 26.6667C34.6151 26.6667 40.5847 20.6972 40.5847 13.3333C40.5847 5.96954 34.6151 0 27.2513 0C19.8875 0 13.918 5.96954 13.918 13.3333C13.918 20.6972 19.8875 26.6667 27.2513 26.6667Z" fill="#D0FE17" />
      <path d="M28.4173 33.3664C19.3173 33.033 0.583984 37.5664 0.583984 46.6664V53.3331H32.384C24.1507 44.1331 28.284 33.6997 28.4173 33.3664Z" fill="#D0FE17" />
      <path d="M58.6837 46.7333C59.8837 44.7666 60.5837 42.4666 60.5837 40C60.5837 32.6333 54.617 26.6666 47.2503 26.6666C39.8837 26.6666 33.917 32.6333 33.917 40C33.917 47.3666 39.8837 53.3333 47.2503 53.3333C49.717 53.3333 52.017 52.6 53.9837 51.4333L62.5504 60L67.2504 55.3L58.6837 46.7333ZM47.2503 46.6666C43.5837 46.6666 40.5837 43.6666 40.5837 40C40.5837 36.3333 43.5837 33.3333 47.2503 33.3333C50.917 33.3333 53.917 36.3333 53.917 40C53.917 43.6666 50.917 46.6666 47.2503 46.6666Z" fill="#D0FE17" />
    </svg>
    :
    <svg width="67" height="60" viewBox="0 0 67 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.0007 26.6667C34.3645 26.6667 40.3341 20.6972 40.3341 13.3333C40.3341 5.96954 34.3645 0 27.0007 0C19.6369 0 13.6674 5.96954 13.6674 13.3333C13.6674 20.6972 19.6369 26.6667 27.0007 26.6667Z" fill="url(#paint0_linear_1342_2985)" />
      <path d="M28.1671 33.3664C19.0671 33.033 0.333771 37.5664 0.333771 46.6664V53.3331H32.1338C23.9005 44.1331 28.0338 33.6997 28.1671 33.3664Z" fill="url(#paint1_linear_1342_2985)" />
      <path d="M58.4333 46.7333C59.6333 44.7666 60.3333 42.4666 60.3333 40C60.3333 32.6333 54.3666 26.6666 47 26.6666C39.6333 26.6666 33.6666 32.6333 33.6666 40C33.6666 47.3666 39.6333 53.3333 47 53.3333C49.4666 53.3333 51.7666 52.6 53.7333 51.4333L62.3 60L67 55.3L58.4333 46.7333ZM47 46.6666C43.3333 46.6666 40.3333 43.6666 40.3333 40C40.3333 36.3333 43.3333 33.3333 47 33.3333C50.6666 33.3333 53.6666 36.3333 53.6666 40C53.6666 43.6666 50.6666 46.6666 47 46.6666Z" fill="url(#paint2_linear_1342_2985)" />
      <defs>
        <linearGradient id="paint0_linear_1342_2985" x1="27.0007" y1="0" x2="27.0007" y2="26.6667" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D0FE17" />
          <stop offset="1" stopColor="#25C928" />
        </linearGradient>
        <linearGradient id="paint1_linear_1342_2985" x1="16.2338" y1="33.3492" x2="16.2338" y2="53.3331" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D0FE17" />
          <stop offset="1" stopColor="#25C928" />
        </linearGradient>
        <linearGradient id="paint2_linear_1342_2985" x1="50.3333" y1="26.6666" x2="50.3333" y2="60" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D0FE17" />
          <stop offset="1" stopColor="#25C928" />
        </linearGradient>
      </defs>
    </svg>

)

export default PersonFind
