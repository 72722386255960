import { useState } from 'react'

declare global {
  interface Window {
    fbq: any
  }
}

export const useFbq = () => {
  const [ready, setReady] = useState(false)

  const interval = window.setInterval(() => {
    if (window.fbq) {
      setReady(true)
      window.clearInterval(interval)
    }
  }, 500)

  return ready
}
