import React from 'react'

interface Props {
  variant?: string
}

const Contact: React.FC<Props> = ({ variant = 'primary' }) => (
  variant === 'primary' ?
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25 12H0.25V54C0.25 57.3 2.95 60 6.25 60H48.25V54H6.25V12ZM54.25 0H18.25C14.95 0 12.25 2.7 12.25 6V42C12.25 45.3 14.95 48 18.25 48H54.25C57.55 48 60.25 45.3 60.25 42V6C60.25 2.7 57.55 0 54.25 0ZM36.25 6C41.23 6 45.25 10.02 45.25 15C45.25 19.98 41.23 24 36.25 24C31.27 24 27.25 19.98 27.25 15C27.25 10.02 31.27 6 36.25 6ZM54.25 42H18.25V37.5C18.25 31.53 30.25 28.5 36.25 28.5C42.25 28.5 54.25 31.53 54.25 37.5V42Z" fill="#D0FE17" />
    </svg>
    :
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12H0V54C0 57.3 2.7 60 6 60H48V54H6V12ZM54 0H18C14.7 0 12 2.7 12 6V42C12 45.3 14.7 48 18 48H54C57.3 48 60 45.3 60 42V6C60 2.7 57.3 0 54 0ZM36 6C40.98 6 45 10.02 45 15C45 19.98 40.98 24 36 24C31.02 24 27 19.98 27 15C27 10.02 31.02 6 36 6ZM54 42H18V37.5C18 31.53 30 28.5 36 28.5C42 28.5 54 31.53 54 37.5V42Z" fill="url(#paint0_linear_1342_2968)" />
      <defs>
        <linearGradient id="paint0_linear_1342_2968" x1="30" y1="0" x2="30" y2="60" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D0FE17" />
          <stop offset="1" stopColor="#25C928" />
        </linearGradient>
      </defs>
    </svg>
)

export default Contact
