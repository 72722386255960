import currency from 'currency.js'

/**
 * @param amount string
 * @returns string
 */
export const moneyFormatter = (amount: string | number) => {
  return currency(String(amount), {
    symbol: '$',
    separator: '.',
    decimal: ',',
    precision: 0
  }).format()
}
