import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
  strokeWidth?: string | number
}

const ChevronDown: React.FC<Props> = ({
  color = '#999',
  width = 28,
  height = 28,
  strokeWidth = 1,
}) => (
  <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
    <path
      d="M8 11L14 17L20 11"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
)

export default ChevronDown
