import classNames from 'classnames';
//import PropTypes from 'prop-types';
import styles from './loader.module.sass';
import LoaderDark from '../../assets/images/loader-dark.png';
import { ReactComponent as IsotypeDark } from '../../assets/icons/isotype-dark.svg';
import LoaderLight from '../../assets/images/loader-light.png';
import { ReactComponent as IsotypeLight } from '../../assets/icons/isotype-light.svg';

/*const propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
  mode: PropTypes.oneOf(['screen', 'full', 'block', 'inline'])
};

const defaultProps = {
  className: null,
  theme: 'light',
  mode: 'inline'
};*/

interface Props {
    className: any,
    theme: string,
    mode: string
}

const Loader: React.FC<Props> = ({ className, theme, mode }) => {
  const wrapperClasses = classNames(styles.wrapper, styles['full'], className);

  return (
    <div className={wrapperClasses}>
      <div className={styles.container}>
        {theme === 'light' ? (
          <>
            <img src={LoaderLight} alt="Loading" />
            <IsotypeLight />
          </>
        ) : (
          <>
            <img src={LoaderDark} alt="Loading" />
            <IsotypeDark />
          </>
        )}
      </div>
    </div>
  );
};

export default Loader;