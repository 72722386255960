import React, { useState } from 'react'
import axios from 'config/axios'
import './styles.scss'
import Check from 'assets/icons/Check'
import Wheel from 'assets/icons/Wheel'
import UploadIcon from 'assets/icons/UploadIcon'
import Delete from 'assets/icons/Delete'
import DeveloperForm from 'components/forms/Developer'
import Loader from 'components/Loader'
import Modal from 'components/Modal'

interface Props {
  initialValues?: any
  isPostulation?: boolean
  idPostulation?: string
}

const FileInput: React.FC<Props> = ({ initialValues, isPostulation = false, idPostulation = '' }) => {
  const [feedback, setFeedback] = useState({
    title: '',
    message: '',
  })
  const [file, setFile] = useState(null);
  const [nameFile, setNameFile] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<{
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    seniority: string,
    tags: { name: string, _id: string }[],
    sassTags: { name: string, _id: string }[],
    salary: string,
    availability: string,
    availabilityType: string,
    coverLetter: string,
    profile: string,
    workModalities: { name: string, _id: string }[],
    nacionality: string,
    country: string,
  }>(initialValues ? {
    firstName: initialValues.firstname || '',
    lastName: initialValues.lastName || '',
    email: initialValues.email || '',
    phone: initialValues.phone || '',
    seniority: initialValues.seniority || '',
    tags: initialValues.tags || [],
    sassTags: initialValues.sassTags || [],
    salary: initialValues.salary || '',
    availability: initialValues.availability || '',
    availabilityType: initialValues.availabilityType || '',
    coverLetter: initialValues.coverLetter || '',
    profile: initialValues.profile || '',
    workModalities: initialValues.workModalities || [],
    nacionality: initialValues.nacionality || '',
    country: initialValues.country || ''
  } :
    {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      seniority: '',
      tags: [],
      sassTags: [],
      salary: '',
      availability: '',
      availabilityType: '',
      coverLetter: '',
      profile: '',
      workModalities: [],
      nacionality: '',
      country: ''
    }
  );

  const [showModal, setShowModal] = useState(false)

  const hideModal = () => {
    setShowModal(false)
    setFeedback({
      title: '',
      message: '',
    })
  }


  const handleChange = async (event: any): Promise<void> => {
    if (event.target.files[0].size < 5242880) {
      setFile(event.target.files[0]);
      const endpoint = '/users/cv/data'
      const formData = new FormData();
      setIsLoading(true);
      setShowForm(false);
      formData.append('cv', event.target.files[0]);
      setNameFile(event.target.files[0].name);
      try {
        await axios.post(endpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          setUserData(response.data);
          setIsLoading(false);
          setShowForm(true);
        })
      } catch (error: any) {
        setUserData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          seniority: '',
          tags: [],
          sassTags: [],
          salary: '',
          availability: '',
          availabilityType: '',
          coverLetter: '',
          profile: '',
          workModalities: [],
          nacionality: '',
          country: ''
        });

        setIsLoading(false);
        setFeedback({
          title: 'Oops...',
          message: error.response.data.message ? error.response.data.message : "Ha ocurrido un error al cargar el PDF, intenta con otro PDF o inténtalo más tarde.",
        })
        setShowModal(true)
        // setShowForm(true);
      }
    } else {
      setNameFile("El tamaño del archivo supera los 5MB");
      setShowForm(false);
    }
  }

  const handleDelete = (event: any) => {
    event.preventDefault();
    setNameFile('');
  }

  return (
    <>
      {showModal && (
        <Modal
          title={feedback.title}
          message={feedback.message}
          onClose={hideModal}
        />
      )}

      <div className='input-box'>
        <div className='label-input'>
          Más Rápido <Check />
          Más Eficiente <Check />
          Powered by Open AI <Wheel />
        </div>
        <div className='border-box'>
          <label className='content-box'>
            <input id="file-upload" type="file" onChange={handleChange} accept="application/pdf" />
            <UploadIcon />
            <label className='name-file'>{nameFile} {nameFile !== '' ? <div style={{ display: 'flex' }} onClick={handleDelete}><Delete /></div> : ''} </label>
            <div className='button-upload'>Cargar CV</div>
            <label className='label-info'>Formato PDF hasta 5MB</label>
          </label>
        </div>
        <div className='show-form'>
          o <label className='green-text' onClick={() => setShowForm(true)}>completa nuestro formulario</label>
        </div>
      </div>
      {isLoading && !showForm && (
        <Loader className='' theme="dark" mode="inline" />
      )}
      {!isLoading && showForm && (
        <DeveloperForm
          file= {file}
          initialValues={{
            country: userData.country || '',
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            email: userData.email || '',
            phone: userData.phone || '',
            seniority: userData.seniority || '',
            abilities: userData.tags.map((tag: any) => ({ id: tag._id, text: tag.name })) || [],
            saasTags: userData.sassTags.map((item: any) => ({ id: item._id, text: item.name })) || [],
            salary: userData.salary || '',
            availability: userData.availability || '',
            availabilityType: userData.availabilityType || '',
            coverLetter: userData.coverLetter || '',
            profile: userData.profile || '',
            workModality: userData.workModalities.map((item: any) => ({ value: item._id, label: item.name })) || [],
            nacionality: userData.nacionality || '',
          }}
          isPostulation={isPostulation}
          idPostulation={idPostulation}
        />
      )}
    </>
  )
}

export default FileInput
