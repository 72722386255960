import React, { useState, useEffect } from 'react'
import axios from 'config/axios'
import { Button } from 'react-bulma-components'

import './styles.scss'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share'
import Twitter from 'assets/icons/Twitter'
import Facebook from 'assets/icons/Facebook'
import LinkedIn from 'assets/icons/LinkedIn'
import Whatsapp from 'assets/icons/Whatsapp'
import { Link } from 'react-router-dom'

interface props {}

const SectionCard: React.FC<props> = () => {
  const [publicData, setPublicData] = useState<{
    type: {name: string},
    availabilityType: {name: string},
    workModality: {name: string},
    project: {company: {name: string}, description: string},
    tags: {name: string}[],
    _id: string,
    salary: number,
    createdAt: string,
    seniority: {name: string},
    serviceType: {name: string},
  }[]>([],);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchPublic(1)
  }, [])

  const fetchPublic = async (actualPage: number) => {
    const response = await axios.get(`/profiles/public?limit=6&page=${actualPage}`)

    if(response && response.data) {
      setTotalPage(response.data.totalPages)
      setPublicData(response.data.docs)
    }
  }

  const backPage = () => {
    if (page > 1) {
      setPage(page - 1)
      fetchPublic(page - 1)
    }
  }

  const nextPage = () => {
    if (page < totalPage) {
      setPage(page + 1)
      fetchPublic(page + 1)
    }
  }
    
  return (
    <div style={{ maxWidth: '85%', margin: 'auto' }}>
      <div className='card-section'>
        {publicData.length > 0 && publicData.map((item, index) => {
          return(
            <div className='card-general' id={`card-${index}-${item._id}`}> 
              <div className='sup-card'>
                <div className='company'>
                  { item.project && item.project.company && item.project.company.name }
                </div>
                <div className='type'>
                  { item.type && item.type.name } { item.seniority && item.seniority.name }
                </div>
                <div className='work-info'>
                  { item.availabilityType && item.availabilityType.name } - { item.workModality && item.workModality.name }
                </div>
              </div>
              <div className='tags-section'>
                {item.tags.length > 0 && item.tags.map((tag, index) => { 
                  return(
                    <div className='tags' key={index}>
                      <label className='tags-label'>{ tag.name }</label>
                    </div>
                  )
                })}
              </div>
              <div>
                <div style={{ width: '100%'}}>
                  <hr style={{ color: '#C4C4C4', backgroundColor: '#C4C4C4', height: 1, width: '100%', border: '0.5px solid #C4C4C4', margin: '0px' }} />
                </div>
                <div className='bot-card'>
                  <div>
                    <label className='compartir-label'>Compartir:</label>
                    <div className='compartir-icono'>
                      <TwitterShareButton
                        url={`https://${window.location.hostname}/postular/${item._id}`}
                        //hashtags={["", ""]}
                        //title="" texto al inicio del tweet
                        //via="" enlaza una cuenta de tw 
                      >
                        <Twitter color='#C4C4C4' width={20} height={20} />
                      </TwitterShareButton>
                      <FacebookShareButton
                        url={`https://${window.location.hostname}/postular/${item._id}`}
                        //hashtag='#' 
                      >
                        <Facebook color='#C4C4C4' width={20} height={20} />
                      </FacebookShareButton>
                      <LinkedinShareButton
                        url={`https://${window.location.hostname}/postular/${item._id}`}
                      >
                        <LinkedIn color='#C4C4C4' width={20} height={20} />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={`https://${window.location.hostname}/postular/${item._id}`}
                        //title="" texto al inicio del msg
                      >
                        <Whatsapp color='#C4C4C4' width={20} height={20} />
                      </WhatsappShareButton>
                    </div>
                  </div>
                  <Link to={`/postular/${item._id}`} style={{ textDecoration: 'none'}}>
                    <div className='postular-boton developer'>
                      Ver anuncio
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {totalPage > 1 && (
        <div className='paginacion'>
          {page > 1 && (
            <Button
              color="warning"
              renderAs="a"
              size="small"
              onClick={backPage}
            >
              Anterior
            </Button>
          )}
          <span style={{ fontWeight: "bold" }}>{page}</span>
          {totalPage > page && (
            <Button
              color="warning"
              renderAs="a"
              size="small"
              onClick={nextPage}
            >
              Siguiente
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default SectionCard
