import React from 'react'
import { Form } from 'react-bulma-components'
import { CustomChangeEvent } from 'hooks/useForm'

import ErrorList from 'components/ErrorList'
const { Field, Label, Control, Input } = Form

interface Props {
  name: string
  value: string
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement> | CustomChangeEvent
  ) => void
  label?: string
  helpText?: string
  placeholder?: string
  type?: string
  id?: string
  errors?: { [key: string]: any } | null
  disabled?: boolean
  readOnly?: boolean
}

const TextField: React.FC<Props> = ({
  name,
  value,
  onChange,
  label,
  helpText,
  placeholder = '',
  type = 'text',
  id,
  errors,
  disabled,
  readOnly,
}) => {
  return (
    <Field>
      {label && (
        <>
          <Label htmlFor={id}>{label}</Label>
          {helpText && <p className="is-size-7 mb-3">{helpText}</p>}
        </>
      )}
      <Control>
        <Input
          value={value}
          onChange={onChange}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
        />
        {errors && errors[name] && <ErrorList errors={errors[name]} />}
      </Control>
    </Field>
  )
}

export default TextField
